const  appUrl={
isProduction:false,
    // fileBaseUrl:'http://localhost:3002/',
    // baseUrl:'http://localhost:3002/api/v1/',
    fileBaseUrl:'https://api.empath.co.in/',
    baseUrl:'https://api.empath.co.in/api/v1/',
    // fileBaseUrl:'http://192.168.18.36:3002/',
    // baseUrl:'http://192.168.18.36:3002/api/v1/',
    loginUser:'auth/user',
    getAllRoles:'role/all',
    getSources:'hlpSource',
    addUser:'user',
    editUser:'user',
    getAllUsers:'user/all',
    userBranches:'branch',
    addBranch:'branch',
    dashboardData:'dashboard',
    comparisonData:'comparison/stat',
    getBranchUsers:'branch/user',
    getOtherUsers:'branch/unassignUser',
    addBranchUser:'user/assignBranch',
    removeBranchUser:'user/unassignBranch',
    getHLPInitData:'branch/sources',
    addHlpData:'hlpData',
    getHlpDateByRange:'hlpData/byDateRange',
    getElectricityBills:'electricityBill',
    addElectricityBills:'electricityBill',
    editElectricityBills:'electricityBill',
    deleteElectricityBills:'electricityBill',
    getHlpDetails:'dashboard/page2',
electricityStats:'electricityBill/newStat',
    addDieselStorage:'dieselStorage/updateDieselStorage',
    editDeiselStorage:'dieselStorage/updateDieselStorage',
    deleteDieselStorage:'electricityBill',
    getDieselStats:'hlpData/dieselStat',
    getDieselStorageList:'dieselStorage'
    


 

}
export  default appUrl;

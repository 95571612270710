import appUrl from '../../constants/appUrl';
import { axiosApi } from '../../services/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const electricitySlice = createSlice({
    name: 'electricitySlice',
    initialState: {},
    reducers: {
        resetAddEditElectricityBill(state, action) {
            return { ...state, addSuccess: undefined ,addError:undefined,deleteSuccess:undefined}
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getElectricityBills.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getElectricityBills.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getElectricityBills.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });

        builder
            .addCase(addElectricityBills.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addElectricityBills.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload ,addSuccess:true}
            })
            .addCase(addElectricityBills.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess:false
                }
            });

  
        builder
            .addCase(editElectricityBills.pending, (state, action) => {
                return { ...state, editLoading: true }
            })
            .addCase(editElectricityBills.fulfilled, (state, action) => {

                return { ...state, editLoading: false, editData: action.payload,editSuccess:true }
            })
            .addCase(editElectricityBills.rejected, (state, action) => {

                return {
                    ...state, editLoading: false,
                    error: action.payload,
                    editSuccess:false
                }
            });
        builder
            .addCase(deleteElectricityBills.pending, (state, action) => {
                return { ...state, deleteLoading: true }
            })
            .addCase(deleteElectricityBills.fulfilled, (state, action) => {

                return { ...state, deleteLoading: false, deleteData: action.payload,deleteSuccess:true }
            })
            .addCase(deleteElectricityBills.rejected, (state, action) => {

                return {
                    ...state, deleteLoading: false,
                    error: action.payload,
                    deleteSuccess:false
                }
            });
        builder
            .addCase(getElectricityStats.pending, (state, action) => {
                return { ...state, statsLoading: true }
            })
            .addCase(getElectricityStats.fulfilled, (state, action) => {

                return { ...state, statsLoading: false, stats: action.payload }
            })
            .addCase(getElectricityStats.rejected, (state, action) => {

                return {
                    ...state, statsLoading: false,
                    error: action.payload,
                    
                }
            });
    },
});

export default electricitySlice.reducer;
export const { resetAddEditElectricityBill } = electricitySlice.actions;



// Thunks
export const getElectricityBills = createAsyncThunk('electricityBills/fetch', async (body, { rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state = getState();
        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
        console.log(body.year);
        console.log(Date.parse(body.year.toString()).year);
        let url=appUrl.baseUrl + appUrl.getElectricityBills;
     
        const { data } = await axiosApi.get(url+`?branch=${body.branch}&year=${body.year.getFullYear()}`,headers);
        console.log(data);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});

export const editElectricityBills = createAsyncThunk('electricityBills/edit', async (body, { rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state = getState();
        console.log(state);

        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
        let url=appUrl.baseUrl + appUrl.editElectricityBills;
        const { data } = await axiosApi.put(url.baseUrl, body,headers);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }

});
export const addElectricityBills = createAsyncThunk('electricityBills/add', async (body, { rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state = getState();
   

        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
        console.log(headers);

        let url=appUrl.baseUrl + appUrl.addElectricityBills;
  
        const { data } = await axiosApi.post(url, body,headers);
        return fulfillWithValue(data.data);
    } catch (error) {
        console.log(error);
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }

});
export const deleteElectricityBills = createAsyncThunk('electricityBills/delete', async (id, { rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state = getState();
        console.log(state);

        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
        let url=appUrl.baseUrl + appUrl.editElectricityBills+`?billId=${id}`;
        const { data } = await axiosApi.delelte(url.baseUrl,headers);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }


});
export const getElectricityStats = createAsyncThunk('electricityStats/fetch', async (id, { rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state = getState();
        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
        let url=appUrl.baseUrl + appUrl.electricityStats+`?branch=${id}`;
        const { data } = await axiosApi.get(url,headers);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }

});

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getBranchUsers, removeUserFromBranch } from '../../../../redux/user_management/branch_user_management_slice';
import AddUserToBranchDialog from '../components/add_user_to_branch_dialog';
import { confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method
import { toast } from 'react-toastify';


export default function BranchUserManagementScreen() {

  const { data: users, loading, error, deleteLoading, deleteSuccess } = useSelector((state) => state.branchUsers);
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  const [addDialog, setAddDialog] = useState(false);
  const [editUser, setEditUser] = useState(null)
  const dialogFuncMap = {
    'addDialog': setAddDialog,

  }

  let delteId;


  useEffect(() => {
    if (deleteSuccess !== undefined) {
      if (deleteSuccess === true) {
        toast.success("User removed");
        dispatch(getBranchUsers(id));
      }
    }


  }, [deleteSuccess])



  const history = useHistory();

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);


  }

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  }


  useEffect(() => {
    dispatch(getBranchUsers(id))
  }, [])


  const removeConfirmation = () => {
    confirmDialog({
      message: 'Are you sure you want to remove this user from branch?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept,
      // reject: () => rejectFunc()
    });
  }

  const accept = () => {
    let data = {
      "userId": delteId,
      "branchId": id
    };
    dispatch(removeUserFromBranch(data));

  }
  const actionTemplate = (row) => {
    return <div className='flex flex-row'>
      <Button icon="pi pi-trash" className="p-button-rounded p-button-primary p-button-danger" onClick={() => {
        delteId = row._id;
        removeConfirmation();



      }} aria-label="Details" />
      {/* <Button icon="pi pi-users" className="ml-2 p-button-rounded p-button-primary" onClick={() => {
          history.push(`/api/branches/user/management/${row._id}`);

      }} aria-label="Details" /> */}
      {/* <Button tooltip='Appointments' icon="pi pi-table" className="ml-2 p-button-rounded p-button-primary" onClick={() => {
          history.push(`/api/centers/appointments/${row.centre._id}`)

      }} aria-label="Details" /> */}
    </div>
  }



  return (
    <>

      <div>
        <Dialog header="Add User" modal={true} visible={addDialog} style={{ width: '50vw' }} onHide={() => onHide('addDialog')}>
          <AddUserToBranchDialog branchId={id} onHide={() => onHide('addDialog')} />
        </Dialog>


        <div className='flex flex-row justify-content-between'>
          <h2 className='text-white'>Users</h2>
          <Button className='customButton' label='Add' onClick={() => {
            onClick('addDialog');
          }} />
        </div>
        <hr></hr>

        <DataTable loading={loading} rows={10} paginator responsiveLayout="scroll" value={users}>

          <Column body={(data, props) => <div>{props.rowIndex + 1}</div>} filter header="Sr" />
          <Column filter field="firstName" header="First Name" />
          <Column filter field="lastName" header="Last Name" />
          <Column filter field="email" header="Email" />
          <Column filter field="role.role" header="Role" />
          <Column body={actionTemplate} />

        </DataTable>

      </div>
    </>
  )
}

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBranchDashboardData, getBranchDashboradDataByMonth, getDashboardData, setGraphType, setSelectedBranch, setSelectedMonth } from '../../../../redux/dashboardSlices/dashboard_data_slice';
import CustomLoading from '../../../components/custom_spinner';
import AllBranchesDialog from '../../branch_management/components/all_branches_dialog';
import { Chart } from 'primereact/chart';
import DatePicker from "react-datepicker";

import { ProgressBar } from 'primereact/progressbar';
import DashboardCostGraphWidget from '../components/dashborad_graph_widget';
import CosumptionsComponenet from '../components/cusumptions_components';
import CostRoomConmponents from '../components/cost_room_components';
import DashboardSourcesComponent from '../components/dashboard_sources';
import DashboardUnitGraphWidget from '../components/dashboard_unit_graph_widget';
import PerRoomCostsComponent from '../components/perRoomCostsComponent';
import { InputText } from 'primereact/inputtext';
import DashbordKWHGraphWidget from '../components/dashboard_kwh_graph_widget';



export default function DashboardScreen() {


  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [selectedCostGraphType, setSelectedCostGraphType] = useState('mtd');

  const { user } = useSelector((state) => state.loginUser);






  const { data: dashboardData, loading, selectedBranch, selectedSource, graphType, selectedMonth } = useSelector((state) => state.dashboardData);
  const [allDialog, setAllDialog] = useState(false);
  const [editData, setEditData] = useState(null)
  const dialogFuncMap = {
    'allDialog': setAllDialog,
  }

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  }

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  }



  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDashboardData())



  }, [])


  return (
    <>
      {loading === true || dashboardData === undefined ?
        <CustomLoading />
        : <div>
          <Dialog header="Branches" modal={true} visible={allDialog} style={{ width: '70vw' }} onHide={() => onHide('allDialog')}>
            <AllBranchesDialog onHide={() => onHide('allDialog')} />
          </Dialog>

          {/* 
          <div>
            <h3 className='text-white text-center'>Dashboard</h3>

          </div> */}


          <div className='flex flex-row flex-wrap'>



            {
              dashboardData.branchesList.length === 0 ?
                <h3 className='text-white'>
                  No branches found
                </h3>
                :

                <>
                  <div onClick={() => {
                    dispatch(setSelectedBranch(undefined));
                    dispatch(getBranchDashboardData('all'));
                  }} key={'test'} style={{ boxSizing: 'border-box' }} className={`card dashboard-card col-6 md:col-3 ${selectedBranch === undefined ? 'selected-branch' : ''}`}>
                    <h4>{user.company.name}</h4>
                  </div>

                  <div className='flex flex-column justify-content-center align-items-center align-content-center text-white'>
                    <h1 className=''>
                      <div>
                        {'=>'}
                      </div>
                    </h1>
                  </div>

                  {dashboardData.branchesList.slice(0, 3).map((item, index) => <div onClick={() => {
                    dispatch(setSelectedBranch(item._id));
                    dispatch(getBranchDashboardData(item._id));
                  }} key={index} style={{ boxSizing: 'border-box' }} className={`card dashboard-card col-6 md:col-3  ${selectedBranch !== undefined && selectedBranch === item._id ? 'selected-branch' : ''}`}>
                    <Link className='link' to={`/api/branches/${item._id}/${item.name}`}>        <h3>{item.name}</h3></Link>
                  </div>




                  )}
                </>
            }

          </div>
          <div className='text-right'>

            <Button className='customButtonSmall' onClick={() => onClick('allDialog')} label='View all' />
          </div>
          {

            dashboardData.branchesList.length > 0 && <div>

              <DashboardSourcesComponent />

              <div className='mb-5 flex justify-content-end align-items-end align-content-end'>
               
               <div className='flex flex-column'> 
                 <div className='text-white text-sm pb-1'>
                  Select month
                  </div>
                  <InputText style={{border:'none'}}  onChange={(e) => {
                    console.log(e.target.value);
                  dispatch(setSelectedMonth(e.target.value));
                  dispatch(getBranchDashboradDataByMonth(e.target.value));
                }} className='mr-2 datepicker-wrapper text-white' value={selectedMonth} type='month' />

                 </div>




               <div>
               <div className='datepicker-wrapper flex flex-row align-content-center align-items-center justify-content-center'>
                  <div>
                    <h6 style={{ margin: '0', cursor: 'pointer' }} onClick={() => {
                      dispatch(setGraphType('ytd'));
                    }} className={`text-white mr-2 ${graphType === 'ytd' ? 'font-bold' : ''}`}>YTD</h6>
                  </div>
                  {/* <DatePicker className='customDatePicker' selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                  <div>
                    <i className="pi pi-calendar text-white mr-1"></i>
                  </div>
                  <div>
                    <h6 style={{ margin: '0', cursor: 'pointer' }} onClick={() => {
                      dispatch(setGraphType('mtd'));
                    }} className={`text-white mx-2 ${graphType === 'mtd' ? 'font-bold' : ''}`}>MTD</h6>
                  </div>
                  {/* <DatePicker className='customDatePicker' selected={endDate} onChange={(date) => setEndDate(date)} /> */}
                  <div>     <i className="pi pi-calendar text-white"></i></div>
                </div>
                 </div>

              </div>


              <div className='grid p-fluid  gap-4'>
                <DashboardCostGraphWidget />


              </div>
              {selectedSource !== 'hlp' && <div className='grid p-fluid mt-4 gap-4'>
                <DashboardUnitGraphWidget />
              </div>}
              {selectedSource === 'diesel' && <div className='grid p-fluid mt-4 gap-4'>
                <DashbordKWHGraphWidget />
              </div>}


              <PerRoomCostsComponent />
            </div>}


        </div>
      }



    </>
  )
}

import appUrl from '../../constants/appUrl';
import { axiosApi } from '../../services/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const dieselManagementSlice = createSlice({
    name: 'dieselManagementSlice',
    initialState: {},
    reducers: {
        resetAddEditDieselStorage(state, action) {
            return { ...state, addSuccess: undefined, addError: undefined, deleteSuccess: undefined ,editSuccess:undefined}
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getDieselStorageList.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getDieselStorageList.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getDieselStorageList.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });

        builder
            .addCase(addDieselStorage.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addDieselStorage.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload, addSuccess: true }
            })
            .addCase(addDieselStorage.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false
                }
            });


        builder
            .addCase(editDieselStorage.pending, (state, action) => {
                return { ...state, editLoading: true }
            })
            .addCase(editDieselStorage.fulfilled, (state, action) => {

                return { ...state, editLoading: false, editData: action.payload, editSuccess: true }
            })
            .addCase(editDieselStorage.rejected, (state, action) => {

                return {
                    ...state, editLoading: false,
                    error: action.payload,
                    editSuccess: false
                }
            });
        builder
            .addCase(delelteDieselStorage.pending, (state, action) => {
                return { ...state, deleteLoading: true }
            })
            .addCase(delelteDieselStorage.fulfilled, (state, action) => {

                return { ...state, deleteLoading: false, deleteData: action.payload, deleteSuccess: true }
            })
            .addCase(delelteDieselStorage.rejected, (state, action) => {

                return {
                    ...state, deleteLoading: false,
                    error: action.payload,
                    deleteSuccess: false
                }
            });
        builder
            .addCase(getDieselStorageStats.pending, (state, action) => {
                return { ...state, statsLoading: true }
            })
            .addCase(getDieselStorageStats.fulfilled, (state, action) => {

                return { ...state, statsLoading: false, stats: action.payload }
            })
            .addCase(getDieselStorageStats.rejected, (state, action) => {

                return {
                    ...state, statsLoading: false,
                    error: action.payload,

                }
            });
    },
});

export default dieselManagementSlice.reducer;
export const { resetAddEditDieselStorage } = dieselManagementSlice.actions;



// Thunks
export const getDieselStorageList = createAsyncThunk('getDieselStorageList/fetch', async (id, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
        let state = getState();
        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
       
        let url = appUrl.baseUrl + appUrl.getDieselStorageList;

        const { data } = await axiosApi.get(url + `?branchId=${id}`, headers);
        console.log(data);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});

export const editDieselStorage = createAsyncThunk('editDieselStorage/edit', async (body, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
        body['dieselStorage']=body['dieselStorage2']
        let state = getState();
      

        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
        let url = appUrl.baseUrl + appUrl.editDeiselStorage;
        const { data } = await axiosApi.patch(url, body, headers);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }

});
export const addDieselStorage = createAsyncThunk('addDieselStorage/add', async (body, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
        let state = getState();


        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
        console.log(headers);

        let url = appUrl.baseUrl + appUrl.addDieselStorage;

        const { data } = await axiosApi.post(url, body, headers);
        return fulfillWithValue(data.data);
    } catch (error) {
        console.log(error);
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }

});
export const delelteDieselStorage = createAsyncThunk('deleteDieselStorage/delete', async (id, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
        let state = getState();
        console.log(state);

        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
        let url = appUrl.baseUrl + appUrl.editDieselStorage + `?billId=${id}`;
        const { data } = await axiosApi.delelte(url.baseUrl, headers);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }


});
export const getDieselStorageStats = createAsyncThunk('getStats/fetch', async ({ branchId, monthYear }, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
        let year = monthYear.split("-")[0];
        let month = parseInt(monthYear.split("-")[1]) - 1;
        let state = getState();
        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
        let url = appUrl.baseUrl + appUrl.getDieselStats + `?branchId=${branchId}&month=${month}&year=${year}`;
        const { data } = await axiosApi.get(url, headers);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }

});

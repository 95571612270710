import appUrl from '../../constants/appUrl';
import { axiosApi } from '../../services/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const getRolesReducer = createSlice({
    name: 'getRoles',
    initialState: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRoles.pending, (state, action) => {
                return { loading: true }
            })
            .addCase(getRoles.fulfilled, (state, action) => {

                return { loading: false, data: action.payload }
            })
            .addCase(getRoles.rejected, (state, action) => {
                return {
                    loading: false,
                    success:false,
                    error: action.payload
                }
            });
    },
});

export default getRolesReducer.reducer;

// Thunks
export const getRoles = createAsyncThunk('getRoles/fetch', async ( ud,{ rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state=getState();
        console.log(state);

        let headers={
            headers: {
              Authorization: 'Bearer ' + state.loginUser.user.token
            }
          };
          console.log(headers);
          let url=appUrl.baseUrl + appUrl.getAllRoles;
          console.log(url);
        const { data } = await axiosApi.get(url,headers);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});






import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import CustomInputField from '../../../components/custom_input_field';
import { getUserBranches } from '../../../../redux/branches/user_branch_slices';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { addElectricityBills, getElectricityBills, resetAddEditElectricityBill } from '../../../../redux/electricity/electricity_slice';
import { FileUpload } from 'primereact/fileupload';
import { toast } from 'react-toastify';
import { addDieselStorage, editDieselStorage, getDieselStorageList, getDieselStorageStats, resetAddEditDieselStorage } from '../../../../redux/diesel_management/diesel_management_slice';


export default function AddEditDieselStorageDialog({ editData, onHide, selectedMonth }) {

  const [month, setMonth] = useState("");


  const { data: branches, loading: branchesLoading } = useSelector((state) => state.userBranches);


  const { success, loading, error, editLoading, addLoading, addError, editError, addSuccess, editSuccess } = useSelector((state) => state.dieselManagement);

  const [image, setImage] = useState(undefined);



  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    dieselStorage: Yup.number().required("Letres are required.").min(1, 'Minumum value is 1'),
    branchId: Yup.string().required("Branch is required."),
    unitPrice: Yup.number().required("Per unit cost is required.").min(1, 'Minumum value is 1'),
    date:editData!==null?null:Yup.date().required("Date is required")





  });



  const formik = useFormik({
    initialValues: {
      "branchId": "",
      "dieselStorage": 0,
      "unitPrice": 0,
      "date": null,


    },
    validationSchema: validationSchema,
    onSubmit: async (data) => {
      if(editData===null){
        dispatch(addDieselStorage(data));

      }else{

        
        data['dieselStorageId']=editData._id;
        data['dieselStorage2']=(data.dieselStorage-editData.storage);

   
        dispatch(editDieselStorage(data));


      }



    },
  });






  useEffect(() => {
    dispatch(getUserBranches())
  }, [])

  const loadInitialValues = () => {
    formik.setFieldValue("branchId", editData.branch._id)
    formik.setFieldValue("dieselStorage", editData.storage)
    formik.setFieldValue("unitPrice", editData.unitPrice)
  }

  useEffect(() => {
    if (editData !== null) {
      loadInitialValues();
    }
  }, []);



  useEffect(() => {


    if (addSuccess !== undefined) {
      if (addSuccess === true) {
        toast.success("Storage added");

        onHide();
        if (selectedMonth) {
          dispatch(getDieselStorageList(formik.values.branchId))
          dispatch(getDieselStorageStats({ branchId: formik.values.branchId, monthYear: selectedMonth }))

        }


      } else {
        toast.error(addError);

      }
    }

    if (editSuccess !== undefined) {
      if (editSuccess === true) {
        toast.success("Storage updated");

        onHide();
        if (selectedMonth) {
          dispatch(getDieselStorageList(formik.values.branchId))
          dispatch(getDieselStorageStats({ branchId: formik.values.branchId, monthYear: selectedMonth }))


        }



      } else {
        toast.error(editError);

      }
    }

    return () => {
      dispatch(resetAddEditDieselStorage());


    }


  }, [addSuccess, addError, editSuccess, editError]);

  return (
    <>
      <div>

        <form onSubmit={formik.handleSubmit}>
          <div className="col-12 md:col-6\8">
            <div className=" p-fluid">

              <CustomInputField formik={formik} placeHolder='Choose branch' type='dropdown' iden='branchId' options={branches} optionLabel='name' optionValue='_id' />
              <CustomInputField min={1} formik={formik} iden='dieselStorage' label="Diesel Storage" type='number' />
              <CustomInputField min={1} formik={formik} iden='unitPrice' label="Per leter price" type='number' />
             
         { editData===null&& <CustomInputField max="<?= date('Y-m-d'); ?>" formik={formik} iden='date' label="Date" type='date' />
}




              <Button loading={editData === null ? addLoading : editLoading} className='customButton' label='Submit' type='submit' />

            </div>
          </div>
        </form>


      </div>

    </>
  )
}



import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getBranchSourceDashboardData, setSelectedSource } from '../../../../redux/dashboardSlices/dashboard_data_slice';

export default function DashboardSourcesComponent() {

  const { data: dashboardData ,selectedSource,selectedBranch} = useSelector((state) => state.dashboardData);
  const selectedItemStyle={
  background:'#842990',
    color:'white',
    cursor:'pointer'
  

  }
  const unselectedItemStyle={
  
    border:'1px solid #842990',
    cursor:'pointer'

  }
  const dispatch=useDispatch();

  const sources=[
  {
    name:'HLP',
    key:'hlp'
  },
  {
    name:'Electricity',
    key:'electricity'
  },
  {
    name:'Solar',
    key:'solar'
  },
  {
    name:'Gas',
    key:'gas'
  },
  {
    name:'Water',
    key:'water'
  },
  {
    name:'Bore-Well/Well',
    key:'well'
  },
  {
    name:'Tanker Water',
    key:'tanker'
  },
  {
    name:'D.G & Diesel',
    key:'diesel'
  },
 

  ]


  

  

  return (
    <div className='card' style={{padding:'1rem'}}>
      <div className='flex flex-row flex-wrap justify-content-center'>
        {
          sources.map((item)=>(
          <div key={item.key} style={selectedSource===item.key?selectedItemStyle:unselectedItemStyle} onClick={()=>{
            


            if(item.key!==selectedSource){
              dispatch(setSelectedSource(item.key));
              dispatch(getBranchSourceDashboardData(item.key));
              
            }
          }} className='border-round px-2 py-1 mx-2  flex flex-row justify-content-center align-items-center align-content-center'>
            {
            <h6 style={{margin:'0'}} className='text-center'>{item.name}</h6>
            }

          </div>))
        }


      </div>


    </div>
  )
}

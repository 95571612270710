import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { number } from 'yup';
import { addHlpData, getHlpInitData, resetAddHlpData } from '../../../../redux/hlp_data/htlp_data_slice';
import CustomInputField from '../../../components/custom_input_field';
import CustomLoading from '../../../components/custom_spinner';

export default function AddHLPEntryDialog({ onHide, id }) {
    const { loading, error, data, addLoading, addError, addSuccess,occupiedRooms:getOccupiedRooms,dataId,dieselStorage } = useSelector((state) => state.hlpData);
    const [values, setValues] = useState([]);
    const [gotData, setGotData] = useState(false);
    const [date, setDate] = useState("");
    const [occupiedRooms, setOccupiedRooms] = useState(0);
    const [oldDieselStorage,setOldDieselStorage]=useState(0);
    const [newDieselStorage,setNewDieselStorage]=useState(0);

    const dispatch = useDispatch();

    useEffect(() => {


        return () => {
            dispatch(resetAddHlpData());
        }
    }, [])

    useEffect(() => {

        if (data !== undefined) {

            setGotData(true);
            console.log(data);
            setValues(data.map((item) =>{


                if(item.source==='63832642ab7479bd3162e923'){
                    setOldDieselStorage(item.units);
                    setNewDieselStorage(item.units);


                    return  ({ ...item,reserved2:'KWH' });
                }
                
                return  ({ ...item });
            }));

            console.log(getOccupiedRooms);
            setOccupiedRooms(getOccupiedRooms)
        }
        return ()=>{


        }


    }, [data])

    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Data added");
                onHide();
            } else {
                toast.error(addError)
            }
        }

        return ()=>{}


    }, [addSuccess])




    const handleSubmit = (e) => {
        e.preventDefault();

        if(dieselStorage<newDieselStorage){
            toast.warn("Please enter valid diesel units")
            return;
        }  
        let body = {


        }
        
        console.log("Updated storage",newDieselStorage);
        console.log("Old storage",oldDieselStorage);
        body['branch'] = id;
        body['date'] = date;
        body['occupiedRooms'] = occupiedRooms;
        body['data'] = values;
        body['dataId'] = dataId;
        body['updatedDieselStorage'] = oldDieselStorage-newDieselStorage;
           
         console.log(body);
       
        
        dispatch(addHlpData(body));
    }

    return (
        <>
            {<div>

                <form onSubmit={handleSubmit}>

                    <div className="col-12 md:col-6\8">
                        <div className=" p-fluid">

                            <div className={`field`}>
                                <label className='text-white' >Date</label>
                                <InputText max={new Date().toISOString().slice(0, -14)} required name='date' type='date' value={date} onChange={(e) => {
                                    setDate(e.target.value);
                                    dispatch(getHlpInitData({ id:id, date: e.target.value }))

                                }} />

                            </div>


                            {
                             data===undefined?<div></div>: (loading === true) ?  <CustomLoading /> :
                             
                             <div>
                                 
                                    <div className={`field`}>
                                            <label className='text-white' >Occupied rooms</label>
                                            <InputText  onWheel={ event => event.currentTarget.blur() } onScroll={ event => event.currentTarget.blur() } onScrollCapture={event => event.currentTarget.blur()}  required min={0} name='units' type='number' value={occupiedRooms} onChange={(e) => {
                                                setOccupiedRooms(e.target.value)


                                            }} />

                                        </div>
                                    {    values.map((item, index) => {
                                    return <div key={index}>
                                        <h4 className='text-white'>{item.sourceName}</h4>

                                        <div className={`field`}>
                                            <label className='text-white' >{item.unit}</label>
                                            <InputText  onWheel={ event => event.currentTarget.blur() } onScroll={ event => event.currentTarget.blur() } onScrollCapture={event => event.currentTarget.blur()}  name='units' type='number' value={item.units} onChange={(e) => {


                                                setValues((prev) => {
                                                    if(item.source==='63832642ab7479bd3162e923'){
                                                        setNewDieselStorage(e.target.value);
                                                    }

                                                    prev[index].units = e.target.value;
                                                    return [...prev];
                                                })
                                            }} />

                                        </div>
                                        <div className={`field`}>
                                            <label className='text-white' >Per {item.unit} cost</label>
                                            <InputText  onWheel={ event => event.currentTarget.blur() } onScroll={ event => event.currentTarget.blur() } onScrollCapture={event => event.currentTarget.blur()}  name='currentYearUnitCost' type='number' value={item.currentYearUnitCost} onChange={(e) => {
                                                setValues((prev) => {
                                                    

                                                    prev[index].currentYearUnitCost = e.target.value;
                                                    return [...prev];
                                                })
                                            }} />

                                        </div>
                                        <div className={`field`}>
                                            <label className='text-white' >Budgeted Units</label>
                                            <InputText name='budgetedUnits' disabled type='number' value={item.budgetedUnits} onChange={(e) => {
                                                setValues((prev) => {
                                                   


                                                    prev[index].budgetedUnits = e.target.value;
                                                    return [...prev];
                                                })
                                            }} />

                                        </div>

                                        {
                                            item.source==='63832642ab7479bd3162e923'&&<div className={`field`}>
                                            <label className='text-white' >KWH</label>
                                            <InputText  onWheel={ event => event.currentTarget.blur() } onScroll={ event => event.currentTarget.blur() } onScrollCapture={event => event.currentTarget.blur()}  name='budgetedUnits'  type='number' min={0} value={item.reserved1} onChange={(e) => {
                                                setValues((prev) => {
                                                    prev[index].reserved1 = e.target.value;
                                                    return [...prev];
                                                })
                                            }} />

                                        </div>
            
                                        }
                                        {
                                            item.source==='63832642ab7479bd3162e923'&&<div className={`field`}>
                                            <label className='text-white' >Diesel Storage</label>
                                            <InputText name='dieselStorage' disabled  type='number' value={dieselStorage} onChange={(e) => {
                                               
                                            }} />

                                        </div>
            
                                        }




                                    </div>
                                })}
                                 </div>
                             
                            
                            }
                            <Button loading={addLoading} className='customButton' label='Submit' type='submit' />

                        </div>


                    </div>


                </form>

            </div>

            }
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import * as Yup from 'yup';
import CustomInputField from './../../../components/custom_input_field'
import { Button } from 'primereact/button';
import { addUser, resetAddUser } from '../../../../redux/user_management/add_user_slice';
import { addBranch } from '../../../../redux/branches/add_branch_slice';
import { getUserBranches } from '../../../../redux/branches/user_branch_slices';
import { addUserToBranch, getBranchUsers, getOtherUsers, resetAddBranchUser } from '../../../../redux/user_management/branch_user_management_slice';
import CustomLoading from '../../../components/custom_spinner';
export default function AddUserToBranchDialog({onHide,branchId}) {

  //hooks
  const {addSuccess,addLoading,addError,otherUsers,otherUsersLoading}=useSelector((state)=>state.branchUsers);
  const {user}=useSelector((state)=>state.loginUser);


const dispatch=useDispatch();
const validationSchema = Yup.object().shape({
    userId: Yup.array().required("Name is required."),
    
  
  });

const formik = useFormik({
  initialValues: {
    "userId": "",

 
  },
  validationSchema: validationSchema,
  onSubmit: async (data) => {
   
    data['branchId']=branchId;
    dispatch(addUserToBranch(data));
  },
});
const [users,setUsers]=useState([]);



useEffect(() => {
if(otherUsers!==undefined){
    setUsers(otherUsers.map((item)=>({userId:item._id,title:`${item.firstName} ${item.lastName} [${item.role.role}] (${item.email})`})))
    console.log(otherUsers);
}
}, [otherUsers])



  useEffect(() => {
    if (addSuccess !== undefined) {
      if (addSuccess === false) {
        toast.error(addError)

      }else{
        onHide();
        dispatch(getBranchUsers(branchId));
      } 
      
    }
  }, [addSuccess]);

  useEffect(() => {
    dispatch(getOtherUsers(branchId))
  
    return () => {
        dispatch(resetAddBranchUser());
    }
  }, [])
  



  return (
<>
{(otherUsersLoading===true||otherUsers===undefined)?<CustomLoading/> :  <div>
     <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
     <div className="col-12 md:col-6\8">
                            <div className=" p-fluid">
 
     <CustomInputField iden='userId' options={users} optionLabel='title' optionValue='userId' formik={formik} placeHolder='Select user' type='multiSelect' />
    
    <Button loading={addLoading} label='Submit' className='customButton' type='submit' />
     </div>

</div>
     </form>

   </div>}</>
  )

}

import React from 'react'
import { useSelector } from 'react-redux';

export default function CosumptionsComponenet({conCY,budgetCY,conLY}) {


    const { data: dashboardData, } = useSelector((state) => state.dashboardData);

  return (
   
     <div className="col-12 md:col-4 flex flex-column py-5">
              <div className='card flex flex-row justify-content-between align-items-center align-content-center py-5'>
                <h5>Consumption(CY)</h5>
                <h5>{conCY}</h5>

              </div>
              <div className='card flex flex-row justify-content-between  align-items-center align-content-center  py-5'>
                <h5>Budget(CY)</h5>
                <h5>{budgetCY}</h5>

              </div>
              <div className='card flex flex-row justify-content-between align-items-center align-content-center  py-5'>
                <h5>Consumption(LY)</h5>
                <h5>{conLY}</h5>

              </div>


            </div>
   
  )
}

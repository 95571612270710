import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Chart } from 'primereact/chart';
import { formatDate } from '../../../../services/date_service';
import CosumptionsComponenet from './cusumptions_components';
import { appCommonService } from '../../../../services/app_common_service';


export default function DashboardCostGraphWidget() {


    var colors = [
        {
            col1: '#5000A3',
            col2: 'rgb(102, 0, 146)'
        },
        {
            col1: 'rgb(255, 162, 24)',
            col2: 'rgb(225, 142, 77)'
        },
        {
            col1: 'rgb(144, 78, 250)',
            col2: 'rgb(211, 98, 226)'
        },
        {
            col1: 'rgb(0, 75, 124)',
            col2: 'rgb(27, 212, 230)'
        },
        {
            col1: '#5000A3',
            col2: 'rgb(102, 0, 146)'
        },
        {
            col1: 'rgb(255, 162, 24)',
            col2: 'rgb(225, 142, 77)'
        },
        {
            col1: 'rgb(144, 78, 250)',
            col2: 'rgb(211, 98, 226)'
        },
        {
            col1: 'rgb(0, 75, 124)',
            col2: 'rgb(27, 212, 230)'
        },

    ]


    const [basicData, setBasicData] = useState({});


    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        categoryPercentage: .4, // here 
        barPercentage: .8,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: 'transparent'
                },

            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: 'transparent'
                }
            }
        }
    };

    const { data: dashboardData, graphType } = useSelector((state) => state.dashboardData);



    function groupBy(arr, criteria) {
        const newObj = arr.reduce(function (acc, currentValue) {
            if (!acc[currentValue[criteria]]) {
                acc[currentValue[criteria]] = [];
            }
            acc[currentValue[criteria]].push(currentValue);
            return acc;
        }, {});
        return newObj;
    }

    useEffect(() => {
        if (dashboardData !== undefined) {
            if (dashboardData.branchSourcesList === null) {
                return;
            }

            let labels = ['CY', 'LY', 'budget'];
            let datasets = [];

            if (graphType === 'mtd') {
                // let mtdData = dashboardData.MTD;

                // const groups = groupBy(dashboardData.MTD, 'date');
                // let keys = Object.keys(groups);

                datasets.push({
                    label: 'CY',
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 360);
                        gradient.addColorStop(0, '#5000A3');
                        gradient.addColorStop(1, 'rgb(102, 0, 146)');
                        return gradient;
                    },
                    borderRadius: 10,
                    data: dashboardData.MTD.map((item) => item.CY),
                    // barThickness: 10,
                });
                datasets.push({
                    label: 'LY',
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 360);
                        gradient.addColorStop(0, 'rgb(0, 75, 124');
                        gradient.addColorStop(1, 'rgb(27, 212, 230)');
                        return gradient;
                    },
                    borderRadius: 10,
                    data: dashboardData.MTD.map((item) => item.LY),
                    // barThickness: 10,
                });
                datasets.push({
                    label: 'Budget',
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 360);
                        gradient.addColorStop(0, 'rgb(144, 78, 250)');
                        gradient.addColorStop(1, 'rgb(211, 98, 226)');
                        return gradient;
                    },
                    borderRadius: 10,
                    data: dashboardData.MTD.map((item) => item.budgeted),
                    // barThickness: 10,
                });


                console.log(datasets);




                setBasicData({
                    labels: dashboardData.MTD.map((item) => formatDate(item._id)),
                    datasets: datasets
                })
            } else {
                let ytdData = dashboardData.YTD;
                datasets.push({
                    label: 'CY',
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 360);
                        gradient.addColorStop(0, '#5000A3');
                        gradient.addColorStop(1, 'rgb(102, 0, 146)');
                        return gradient;
                    },
                    borderRadius: 10,
                    data: ytdData.map((item) => item.CY),
                    // barThickness: 10,
                });
                datasets.push({
                    label: 'LY',
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 360);
                        gradient.addColorStop(0, 'rgb(0, 75, 124');
                        gradient.addColorStop(1, 'rgb(27, 212, 230)');
                        return gradient;
                    },
                    borderRadius: 10,
                    data: ytdData.map((item) => item.LY),
                    // barThickness: 10,
                });
                datasets.push({
                    label: 'Budget',
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 360);
                        gradient.addColorStop(0, 'rgb(144, 78, 250)');
                        gradient.addColorStop(1, 'rgb(211, 98, 226)');
                        return gradient;
                    },
                    borderRadius: 10,
                    data: ytdData.map((item) => item.budgeted),
                    // barThickness: 10,
                });


                console.log(datasets);




                setBasicData({
                    labels: dashboardData.YTD.map((item) => appCommonService.getMonthName(item.month)),
                    datasets: datasets
                })


            }
        }


    }, [graphType, dashboardData])

   



    return (
        <>
            {
                <div style={{padding:'10px 1rem 10px 1rem'}} className="card relative  col-12 md:col-8">
                    <h5 style={{padding:0,margin:0}}>Cost</h5>

                    <Chart type="bar" data={basicData} options={basicOptions} />

                </div>

            }


            {<CosumptionsComponenet conCY={graphType === 'mtd' ? dashboardData.consumptionMTD[0]?.cost : dashboardData.consumptionYTD[0]?.cost} conLY={graphType === 'mtd' ? dashboardData.consumptionMTD[0]?.lastYearCost : dashboardData.consumptionYTD[0]?.lastYearCost} budgetCY={graphType === 'mtd' ? dashboardData.consumptionMTD[0]?.budgetedCost : dashboardData.consumptionYTD[0]?.budgetedCost} />
            }
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import AddNewUserDialog from '../components/add_new_user_dialog';
import { useDispatch, useSelector } from 'react-redux';
import { getRoles } from '../../../../redux/user_management/get_roles_slice';
import { getAllUsers } from '../../../../redux/user_management/get_all_user_slice';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

export default function UserManagementScreen() {

    const {data:users,loading}=useSelector((state)=>state.getAllUsers);

    const [addDialog, setAddDialog] = useState(false);
    const [editUser,setEditUser]=useState(null)
    const dialogFuncMap = {
        'addDialog': setAddDialog,
      
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

      
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const dispatch=useDispatch();


    useEffect(() => {
        dispatch(getRoles());
        dispatch(getAllUsers());
    }, [])
    


    const actionTemplate = (rowData) => {
        return (
        
                <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: "top" }} onClick={() => {
                    setEditUser(rowData);
                    onClick('addDialog');

                }} className="edit mr-2" />

                
        );
    };




  return (
    <>
  <div>
  <Dialog header="Add User" modal={true} visible={addDialog} style={{ width: '50vw' }} onHide={() => onHide('addDialog')}>
              <AddNewUserDialog editUser={editUser} onHide={() => onHide('addDialog')} />
                </Dialog>
  <div className='flex flex-row justify-content-between'>
        <h2 className='text-white'>Users</h2>
        <Button className='customButton' label='Add' onClick={()=>{
            setEditUser(null);
            onClick('addDialog');
        }}/>
    </div>


          <hr></hr>
    <DataTable loading={loading} rows={10} paginator responsiveLayout="scroll" value={users}>

<Column body={(data, props) => <div>{props.rowIndex + 1}</div>} filter header="Sr" />
<Column filter field="firstName" header="First Name" />
<Column filter field="lastName" header="Last Name" />
<Column filter field="email" header="Email" />
<Column filter field="role.role" header="Role" />
<Column body={actionTemplate} />

</DataTable>


    
  </div>
    </>
  )
}

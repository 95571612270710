import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import GraphComponents from './graph_component'

export default function ElectricityTopGraphs() {
  const { stats } = useSelector((state) => state.electricitySlice);
  const [values, setValues] = useState();


  useEffect(() => {
    if (stats) {
      let data = {};
      //pf
      let pf = stats.PF;
      data = {
        ...data,
        pf: {
          title: "PF",
          values: [
            {
              title: "Last Month",
              value: pf.LM,
              percent: ((pf.LM / 1) * 100)
            },
            {
              title: "Last Year",
              value: pf.YTD,
              percent: ((pf.YTD / 1) * 100)
            },
            {
              title: "Current",
              value: pf.CM,
              percent: ((pf.CM / 1) * 100)
            },
          ]
        }
      }
         //contract demand
         let cd = stats.MD;
         let {load}=stats.branch;
         data = {
           ...data,
           cd: {
             title: "Contract Demand",
             values: [
               {
                 title: "Last Month",
                 value: cd.LM,
                 percent: ((cd.LM / load) * 100)
               },
               {
                 title: "Last Year",
                 value: cd.YTD,
                 percent: ((cd.YTD / load) * 100)
               },
               {
                 title: "Current",
                 value: cd.CM,
                 percent: ((cd.CM / load) * 100)
               },
             ]
           }
         }
         //cost
         let cost = stats.cost;
        
         data = {
           ...data,
           cost: {
             title: "Cost",
             values: [
               {
                 title: "Last Month Bill",
                 value: cost.lastMonth,
                 percent: ((cost.lastMonth / (cost.lastMonth*1.10)) * 100)
               },
               {
                 title: "Last Month Data",
                 value: cost.lastMonthData,
                 percent: ((cost.lastMonthData / (cost.lastMonthData*1.10)) * 100)
               },
               {
                 title: "Current Month",
                 value: cost.currentMonthData,
                 percent: ((cost.currentMonthData / (cost.currentMonthData *1.10)) * 100)
               },
             ]
           }
         }
         //units
         let unit = stats.units;
        
         data = {
           ...data,
           unit: {
             title: "Unit",
             values: [
               {
                 title: "Last Month Bill",
                 value: unit.lastMonth,
                 percent: ((unit.lastMonth / (unit.lastMonth*1.10)) * 100)
               },
               {
                 title: "Last Month Data",
                 value: unit.lastMonthData,
                 percent: ((unit.lastMonthData / (unit.lastMonthData*1.10)) * 100)
               },
               {
                 title: "Current Month",
                 value: unit.currentMonthData,
                 percent: ((unit.currentMonthData / (unit.currentMonthData *1.10)) * 100)
               },
             ]
           }
         }
      setValues(data);

    }
  }, [stats])

  return (
    <>
      {values && <div className='grid p-fluid'>
        <div className='col'>
          <GraphComponents title={values?.pf?.title} values={values?.pf?.values} />
        </div>
        <div className='col'>
          <GraphComponents title={values.cd.title} values={values?.cd?.values} />
        </div>
        <div className='col'>
          <GraphComponents title={values?.cost?.title} values={values?.cost?.values} />
        </div>
        <div className='col'>
          <GraphComponents title={values.unit.title} values={values.unit.values} />
        </div>

      </div>}
    </>
  )
}

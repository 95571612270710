import Axios from 'axios';
import appUrl from '../../constants/appUrl';
import { axiosApi } from '../../services/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const getAllUsersReducer = createSlice({
    name: 'getAllUsers',
    initialState: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllUsers.pending, (state, action) => {
                return { loading: true }
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {

                return { loading: false, data: action.payload }
            })
            .addCase(getAllUsers.rejected, (state, action) => {
                return {
                    loading: false,
                    error: action.payload
                }
            });
    },
});

export default getAllUsersReducer.reducer;

// Thunks
export const getAllUsers = createAsyncThunk('getAllUsers/fetch', async ( body,{ rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state=getState();
        let headers={
            headers: {
              Authorization: 'Bearer ' + state.loginUser.user.token
            }
          };
          console.log(headers);
          let url=appUrl.baseUrl + appUrl.getAllUsers;
          console.log(url);
        const { data } = await axiosApi.get(url,headers);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});






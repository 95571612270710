import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import CustomInputField from '../../../components/custom_input_field';
import { getUserBranches } from '../../../../redux/branches/user_branch_slices';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { addElectricityBills, getElectricityBills, resetAddEditElectricityBill } from '../../../../redux/electricity/electricity_slice';
import { FileUpload } from 'primereact/fileupload';
import { toast } from 'react-toastify';


export default function AddEditElectricityBill({ editData, onHide }) {

  const [month, setMonth] = useState("");


  const { data: branches, loading: branchesLoading } = useSelector((state) => state.userBranches);


  const { success, loading, error, editLoading, addLoading, addError, editError, addSuccess, editSuccess } = useSelector((state) => state.electricitySlice);

  const [image,setImage]=useState(undefined);



  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    branch: Yup.string().required("Branch is required."),
    cost: Yup.number().required("Cost is required.").min(0, 'Minumum value is 0'),
    units: Yup.number().required("Units is required.").min(0, 'Minumum value is 0'),
    rate: Yup.number().required("Rate is required.").min(0, 'Minumum value is 0'),
    PF:Yup.number()
    .min(0.80, 'Number must be between 0.8 and 1')
    .max(0.99, 'Number must be between 0.8 and 1')
    .typeError('Invalid number')
    .required('Number input is required'),
    MD: Yup.number().required("MD is required.").min(0, 'Minumum value is 0'),
    AD: Yup.number().required("AD is required.").min(0, 'Minumum value is 0'),




  });

  async function readFileAsDataURL(file) {
    let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
    });



    return result_base64;
}

  const formik = useFormik({
    initialValues: {
      "branch": "",

      "cost": 1,
      "units": 1,
      "rate": 1,
      "PF": 0,
      "MD": 0,
      "AD": 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (data) => {
      if(image){
        let profileImgBase64 = await readFileAsDataURL(image);
        data['image']=profileImgBase64
      }
      let getMonth=month.split('-')[1];
      let getYear=month.split('-')[0];
      data['month']=getMonth;
      data['year']=getYear;

      console.log(data);
      
      dispatch(addElectricityBills(data));
    },
  });






  useEffect(() => {
    dispatch(getUserBranches())
  }, [])

  useEffect(() => {


    if (addSuccess !== undefined) {
      if (addSuccess === true) {
        toast.success("Bill added");

        onHide();
        dispatch(getElectricityBills());


      } else {
        toast.error(addError);

      }
    }

    if (editSuccess !== undefined) {
      if (editSuccess === true) {
        toast.success("Bill modified");

        onHide();
        dispatch(getElectricityBills());


      } else {
        toast.error(editError);

      }
    }

    return () => {
      dispatch(resetAddEditElectricityBill());


    }


  }, [addSuccess, addError, editSuccess, editError]);

  return (
    <>
      <div>

        <form onSubmit={formik.handleSubmit}>
          <div className="col-12 md:col-6\8">
            <div className=" p-fluid">
            <div className={`field`}>
                                <label className='text-white'>Month and year</label>
                                <InputText className='customInput' required name='month' type='month' value={month} onChange={(e) => {
                                    setMonth(e.target.value);

                                }} />

                            </div>
              <CustomInputField formik={formik} placeHolder='Choose branch' type='dropdown' iden='branch' options={branches} optionLabel='name' optionValue='_id' />
              <CustomInputField min={1} formik={formik} iden='cost' type='number' />
              <CustomInputField min={1} formik={formik} iden='units' type='number' />
              {/* <CustomInputField min={1} formik={formik} disabled={true}  iden='rate' type='number' value={formik.values.cost*formik.values.units} step="0.01" /> */}
              <CustomInputField formik={formik} iden='PF' type='number' />
              {/* <CustomInputField min={0} formik={formik} iden='MD' type='number' /> */}
              <CustomInputField min={0} formik={formik} iden='AD' type='number' />
              <div className='field'>
              <InputText className='customInput' type='file' accept='image/*'  onChange={(e)=>setImage(e.target.files[0])} />
              </div>
  
              
              <Button loading={addLoading} className='customButton' label='Submit' type='submit' />

            </div>
          </div>
        </form>


      </div>

    </>
  )
}



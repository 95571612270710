import appUrl from '../../constants/appUrl';
import { axiosApi } from '../../services/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const branchUserManagmentSlice = createSlice({
    name: 'branchUserManagmentSlice',
    initialState: {},
    reducers: {
        resetAddBranchUser(state, action) {
            return { ...state, addSuccess: undefined, addError: undefined, deleteSuccess: undefined }
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getBranchUsers.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getBranchUsers.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getBranchUsers.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });
        builder
            .addCase(getOtherUsers.pending, (state, action) => {
                return { ...state, otherUsersLoading: true }
            })
            .addCase(getOtherUsers.fulfilled, (state, action) => {

                return { ...state, otherUsersLoading: false, otherUsers: action.payload }
            })
            .addCase(getOtherUsers.rejected, (state, action) => {

                return {
                    ...state,
                    otherUsersLoading: false,
                    otherUsersError: action.payload
                }
            });

        builder
            .addCase(addUserToBranch.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addUserToBranch.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload, addSuccess: true }
            })
            .addCase(addUserToBranch.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false
                }
            });
        builder
            .addCase(editAds.pending, (state, action) => {
                return { ...state, editLoading: true }
            })
            .addCase(editAds.fulfilled, (state, action) => {

                return { ...state, editLoading: false, editData: action.payload, editSuccess: true }
            })
            .addCase(editAds.rejected, (state, action) => {

                return {
                    ...state, editLoading: false,
                    error: action.payload,
                    editSuccess: false
                }
            });
        builder
            .addCase(removeUserFromBranch.pending, (state, action) => {
                return { ...state, deleteLoading: true }
            })
            .addCase(removeUserFromBranch.fulfilled, (state, action) => {

                return { ...state, deleteLoading: false, deleteData: action.payload, deleteSuccess: true }
            })
            .addCase(removeUserFromBranch.rejected, (state, action) => {

                return {
                    ...state, deleteLoading: false,
                    error: action.payload,
                    deleteSuccess: false
                }
            });
    },
});

export default branchUserManagmentSlice.reducer;
export const { resetAddBranchUser } = branchUserManagmentSlice.actions;



// Thunks
export const getBranchUsers = createAsyncThunk('branchesUser/fetch', async (id, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
        let state = getState();
        console.log(state);

        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
        let url = appUrl.baseUrl + appUrl.getBranchUsers;
        console.log();
        const { data } = await axiosApi.get(url + `?branchId=${id}`, headers);
        console.log(data);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const getOtherUsers = createAsyncThunk('otherBranches/fetch', async (id, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
        let state = getState();


        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getOtherUsers + `?brachId=${id}&companyId=${state.loginUser.user.company._id}`, headers);

        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const addUserToBranch = createAsyncThunk('ads/add', async (body, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
        let state = getState();
        console.log(state);

        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addBranchUser, body, headers);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const editAds = createAsyncThunk('ads/edit', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.put(appUrl.baseUrl + appUrl.baseUrl, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }

});
export const removeUserFromBranch = createAsyncThunk('ads/delete', async (body, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
        let state = getState();


        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };

        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.removeBranchUser, body, headers);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});

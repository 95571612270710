import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Chart } from 'primereact/chart';
import { formatDate } from '../../../../services/date_service';
import CosumptionsComponenet from './cusumptions_components';


export default function DashboardUnitGraphWidget() {


    var colors = [
        {
            col1: '#5000A3',
            col2: 'rgb(102, 0, 146)'
        },
        {
            col1: 'rgb(255, 162, 24)',
            col2: 'rgb(225, 142, 77)'
        },
        {
            col1: 'rgb(144, 78, 250)',
            col2: 'rgb(211, 98, 226)'
        },
        {
            col1: 'rgb(0, 75, 124)',
            col2: 'rgb(27, 212, 230)'
        },
        {
            col1: '#5000A3',
            col2: 'rgb(102, 0, 146)'
        },
        {
            col1: 'rgb(255, 162, 24)',
            col2: 'rgb(225, 142, 77)'
        },
        {
            col1: 'rgb(144, 78, 250)',
            col2: 'rgb(211, 98, 226)'
        },
        {
            col1: 'rgb(0, 75, 124)',
            col2: 'rgb(27, 212, 230)'
        },

    ]


    const [basicData, setBasicData] = useState({});


    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        categoryPercentage: .4, // here 
        barPercentage: 0.6,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: 'transparent'
                },

            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: 'transparent'
                }
            }
        }
    };

    const { data: dashboardData ,graphType} = useSelector((state) => state.dashboardData);



    function groupBy(arr, criteria) {
        const newObj = arr.reduce(function (acc, currentValue) {
            if (!acc[currentValue[criteria]]) {
                acc[currentValue[criteria]] = [];
            }
            acc[currentValue[criteria]].push(currentValue);
            return acc;
        }, {});
        return newObj;
    }

    useEffect(() => {
        if (dashboardData !== undefined) {
            if(dashboardData.branchSourcesList===null){
                return ;
            }

            let labels =['CY','LY','budget'];
            let datasets = [];

            if (graphType === 'mtd') {
                // let mtdData = dashboardData.MTD;

                
                // const groups = groupBy(dashboardData.MTD, 'date');
                // let keys = Object.keys(groups);

                datasets.push({
                    label: 'CY',
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 360);
                        gradient.addColorStop(0, '#5000A3');
                        gradient.addColorStop(1, 'rgb(102, 0, 146)');
                        return gradient;
                    },
                    borderRadius: 10,
                    data: dashboardData.MTDUnits.map((item)=>item.CY),
                    // barThickness: 10,
                });
                datasets.push({
                    label: 'LY',
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 360);
                        gradient.addColorStop(0, 'rgb(0, 75, 124');
                        gradient.addColorStop(1, 'rgb(27, 212, 230)');
                        return gradient;
                    },
                    borderRadius: 10,
                    data: dashboardData.MTDUnits.map((item)=>item.LY),
                    // barThickness: 10,
                });
                datasets.push({
                    label: 'Budget',
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 360);
                        gradient.addColorStop(0, 'rgb(144, 78, 250)');
                        gradient.addColorStop(1, 'rgb(211, 98, 226)');
                        return gradient;
                    },
                    borderRadius: 10,
                    data: dashboardData.MTDUnits.map((item)=>item.budgeted),
                    // barThickness: 10,
                });

          
                console.log(datasets);



              
                setBasicData({
                    labels: dashboardData.MTDUnits.map((item)=>formatDate(item.date)),
                    datasets: datasets
                })
            } else {
                let ytdData = dashboardData.YTDUnits;
                datasets.push({
                    label: 'CY',
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 360);
                        gradient.addColorStop(0, '#5000A3');
                        gradient.addColorStop(1, 'rgb(102, 0, 146)');
                        return gradient;
                    },
                    borderRadius: 10,
                    data: ytdData.map((item)=>item.CY),
                    // barThickness: 10,
                });
                datasets.push({
                    label: 'LY',
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 360);
                        gradient.addColorStop(0, 'rgb(0, 75, 124');
                        gradient.addColorStop(1, 'rgb(27, 212, 230)');
                        return gradient;
                    },
                    borderRadius: 10,
                    data: ytdData.map((item)=>item.LY),
                    // barThickness: 10,
                });
                datasets.push({
                    label: 'Budget',
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 360);
                        gradient.addColorStop(0, 'rgb(144, 78, 250)');
                        gradient.addColorStop(1, 'rgb(211, 98, 226)');
                        return gradient;
                    },
                    borderRadius: 10,
                    data: ytdData.map((item)=>item.budgeted),
                    // barThickness: 10,
                });

          



              
                setBasicData({
                    labels: dashboardData.YTDUnits.map((item)=>getMonthName(item.month)),
                    datasets: datasets
                })
               



            }
        }


    }, [graphType, dashboardData])

    function getMonthName(month) {
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',                      'July', 'August', 'September', 'October', 'November', 'December'];
        return monthNames[month - 1];
      }



    return (
        <>
           {<div  className="card relative col-12 md:col-8">
                <h5>Units</h5>
                <Chart type="bar" data={basicData} options={basicOptions} />

             
            </div>}
            
{ <CosumptionsComponenet conCY={graphType==='mtd'?dashboardData.consumptionMTD[0]?.units:dashboardData.consumptionYTD[0]?.units} conLY={graphType==='mtd'?dashboardData.consumptionMTD[0]?.lastYearUnits:dashboardData.consumptionYTD[0]?.lastYearUnits} budgetCY={graphType==='mtd'?dashboardData.consumptionMTD[0]?.budgetedUnits:dashboardData.consumptionYTD[0]?.budgetedUnits} />
}
            </>
    )
}

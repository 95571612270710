import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CostRoomConmponents from './cost_room_components';

export default function PerRoomCostsComponent() {

    const { data: dashboardData, selectedBranch, selectedSource, graphType } = useSelector((state) => state.dashboardData);


    const history = useHistory();
    return (
        <>


            {

                dashboardData.perRoomCostMTD.length > 0 && graphType === 'mtd' &&
                <div className='col-12'>

                    <div className='grid p-fluid mt-4 gap-2'>
                        {<CostRoomConmponents heading={"Cost per available rooms"}
                            list={[
                                { title: 'Cost', value: dashboardData.perRoomCostMTD[0]?.totalRoomsAvg },
                                { title: 'Cost LY', value: dashboardData.perRoomCostMTD[0]?.totalRoomsAvgLY },
                                { title: 'Budgeted Cost', value: dashboardData.perRoomCostMTD[0]?.totalRoomsAvgBudgeted },
                            ]}

                        />}
                        <CostRoomConmponents heading={"Cost per occupied rooms"}
                            list={[
                                { title: 'Cost', value: dashboardData.perRoomCostMTD[0]?.occupiedRoomsAvg },
                                { title: 'Cost LY', value: dashboardData.perRoomCostMTD[0]?.occupiedRoomsAvgLY },
                                { title: 'Budgeted Cost', value: dashboardData.perRoomCostMTD[0]?.occupiedRoomsAvgBudgeted },
                            ]}
                        />

                        {selectedSource !== 'hlp' && <CostRoomConmponents heading={"Units per availble rooms"}


                            list={[
                                { title: 'Unis', value: dashboardData.perRoomCostMTD[0]?.totalRoomsUnitAvg },
                                { title: 'Units LY', value: dashboardData.perRoomCostMTD[0]?.totalRoomsUnitAvgLY },
                                { title: 'Budgeted units', value: dashboardData.perRoomCostMTD[0]?.totalRoomsUnitAvgBudgeted },
                            ]}
                        />}
                        {selectedSource !== 'hlp' && <CostRoomConmponents heading={"Units per occupied rooms"}


                            list={[
                                { title: 'Units', value: dashboardData.perRoomCostMTD[0]?.occupiedRoomsUnitAvg },
                                { title: 'Units LY', value: dashboardData.perRoomCostMTD[0]?.occupiedRoomsUnitAvgLY },
                                { title: 'Budgeted Units', value: dashboardData.perRoomCostMTD[0]?.occupiedRoomsUnitAvgBudgeted },
                            ]}


                        />}




                    </div>


                </div>
            }
            {

                dashboardData.perRoomCostYTD.length > 0 &&graphType==='ytd'&&
                <div className='col-12'>
                  

                    <div className='grid p-fluid mt-4 gap-2'>
                        { <CostRoomConmponents heading={"Cost per available rooms"}
                            list={[
                                { title: 'Cost', value: dashboardData.perRoomCostYTD[0]?.totalRoomsAvg },
                                { title: 'Cost LY', value:  dashboardData.perRoomCostYTD[0]?.totalRoomsAvgLY },
                                { title: 'Budgeted Cost', value: dashboardData.perRoomCostYTD[0]?.totalRoomsAvgBudgeted },
                            ]}

                        />}
                        <CostRoomConmponents heading={"Cost per occupied rooms"}
                            list={[
                                { title: 'Cost', value:  dashboardData.perRoomCostYTD[0]?.occupiedRoomsAvg },
                                { title: 'Cost LY', value: dashboardData.perRoomCostYTD[0]?.occupiedRoomsAvgLY },
                                { title: 'Budgeted Cost', value:dashboardData.perRoomCostYTD[0]?.occupiedRoomsAvgBudgeted },
                            ]}
                        />

                        {selectedSource !== 'hlp' && <CostRoomConmponents heading={"Units per availble rooms"}


                            list={[
                                { title: 'Unis', value:   dashboardData.perRoomCostYTD[0]?.totalRoomsUnitAvg },
                                { title: 'Units LY', value:   dashboardData.perRoomCostYTD[0]?.totalRoomsUnitAvgLY },
                                { title: 'Budgeted units', value:  dashboardData.perRoomCostYTD[0]?.totalRoomsUnitAvgBudgeted },
                            ]}
                        />}
                        {selectedSource !== 'hlp' && <CostRoomConmponents heading={"Units per occupied rooms"}


                            list={[
                                { title: 'Units', value:  dashboardData.perRoomCostYTD[0]?.occupiedRoomsUnitAvg },
                                { title: 'Units LY', value:   dashboardData.perRoomCostYTD[0]?.occupiedRoomsUnitAvgLY },
                                { title: 'Budgeted Units', value:   dashboardData.perRoomCostYTD[0]?.occupiedRoomsUnitAvgBudgeted },
                            ]}


                        />}




                    </div>


                </div>
            }



        </>

    )
}

import appUrl from '../../constants/appUrl';
import { axiosApi } from '../../services/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const hlpDetailsSlice = createSlice({
    name: 'hlpDetailsSlice',
    initialState: {},
    reducers: {
      

    },
    extraReducers: (builder) => {
        builder
            .addCase(getHlpDetails.pending, (state, action) => {
                return { loading: true }
            })
            .addCase(getHlpDetails.fulfilled, (state, action) => {

                return { loading: false, data: action.payload }
            })
            .addCase(getHlpDetails.rejected, (state, action) => {

                return {
                   
                    loading: false,
                    error: action.payload
                }
            });

    
    },
});

export default hlpDetailsSlice.reducer;



// Thunks
export const getHlpDetails = createAsyncThunk('hlpDetails/fetch', async (body, { rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state = getState();
        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
     
        let url=appUrl.baseUrl + appUrl.getHlpDetails;
     
        const { data } = await axiosApi.get(url+`?branchId=${body.branchId}&source=${body.sourceId}`,headers);
   
        return fulfillWithValue(data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});



import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getUserBranches } from '../../../../redux/branches/user_branch_slices';
import { getDieselStorageList, getDieselStorageStats } from '../../../../redux/diesel_management/diesel_management_slice';
import { formatDate } from '../../../../services/date_service';
import AddEditDieselStorageDialog from '../components/add_edit_diesel_storage_dialog';

export default function DieselManagementScreen() {
  const { data: branches, loading } = useSelector((state) => state.userBranches);
  const [addDialog, setAddDialog] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(undefined);
  const { stats, statsLoading,data:list } = useSelector((state) => state.dieselManagement);
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedGraphType, setSelectedCostGraphType] = useState("ytd");

  const [editData,setEditData]=useState();

  const dispatch = useDispatch();




  const dialogFuncMap = {
    'addDialog': setAddDialog,

  }


  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  }

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  }

  const returnTopButtons = () => {
    return <div className='mb-4 flex flex-row justify-content-between align-items-center align-content-center '>

      <div>
        <Dropdown className='customDropDown' style={{
          background: 'linear-gradient(to bottom, #7c5382, #842990)',
          borderRadius: '4px',
          color: "white !important"
        }} placeholder='Select' optionLabel='name' optionValue='_id' value={selectedBranch} onChange={(e) => setSelectedBranch(e.target.value)} options={branches} />
      </div>


      <div className='flex flex-row align-content-center align-items-center gap-3'>

        <div className='' >

          <div className='flex flex-row justify-content-start'>
            <InputText onChange={(e) => {

              setSelectedMonth(e.target.value);
            }} className='mr-2' value={selectedMonth} type='month' />
          </div>

        </div>
        <div className='datepicker-wrapper flex flex-row align-content-center align-items-center justify-content-center  mx-2'>
          <div>
            <h6 style={{ margin: '0', cursor: 'pointer' }} onClick={() => {
              setSelectedCostGraphType('ytd')
            }} className={`text-white mr-2 ${selectedGraphType === 'ytd' ? 'font-bold' : ''}`}>YTD</h6>
          </div>
          {/* <DatePicker className='customDatePicker' selected={startDate} onChange={(date) => setStartDate(date)} /> */}
          <div>
            <i className="pi pi-calendar text-white mr-1"></i>
          </div>
          <div>
            <h6 style={{ margin: '0', cursor: 'pointer' }} onClick={() => {
              setSelectedCostGraphType('mtd')
            }} className={`text-white mx-2 ${selectedGraphType === 'mtd' ? 'font-bold' : ''}`}>MTD</h6>
          </div>
          {/* <DatePicker className='customDatePicker' selected={endDate} onChange={(date) => setEndDate(date)} /> */}
          <div>     <i className="pi pi-calendar text-white"></i></div>
        </div>



        <Button className='customButton' label='Add New' onClick={() => {
          setEditData(null);
          onClick('addDialog');

        }} />

      </div>

    </div>

  }

  useEffect(() => {
    dispatch(getUserBranches());
  }, [])


  useEffect(() => {
    if (selectedBranch !== undefined && selectedMonth !== undefined) {
      dispatch(getDieselStorageStats({ branchId: selectedBranch, monthYear: selectedMonth }))
    }
  }, [selectedBranch, selectedMonth, dispatch])
  useEffect(()=>{
if(selectedBranch){
  dispatch(getDieselStorageList(selectedBranch))
}

  },[selectedBranch,dispatch])

  const returnCustomCard = (title, value) => {
    return (<div className='flex flex-column'>

      <div className='card text-center'>
        <span style={{ fontSize: '40px' }}>
          {value}
        </span>
        <br></br>
        -{title}
      </div>
    </div>)

  }
  const getValues = (mtdValue, ytdValue) => {
    console.log(mtdValue,ytdValue);
    if(ytdValue&&selectedGraphType==='ytd'){

 
      return ytdValue.toFixed(2);
  }else if(mtdValue&&selectedGraphType==='mtd'){
    return mtdValue.toFixed(2);
  }
  
  else{
    console.log("Returning null");
    return '';
  }
  }

  const actionTemplate = (rowData) => {
    return (
    
            <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: "top" }} onClick={() => {
              setEditData(rowData);
                onClick('addDialog');

            }} className="edit mr-2" />

            
    );
};



  return (
    <>
      <div className='text-center'>
        <Dialog header="Diesel" modal={true} visible={addDialog} style={{ width: '50vw' }} onHide={() => onHide('addDialog')}>
          <AddEditDieselStorageDialog editData={editData} selectedMonth={selectedMonth} onHide={() => onHide('addDialog')} />
        </Dialog>

        <h3 className='text-white'>Diesel Storage Managment</h3>
      </div>
      {returnTopButtons()}


      {
        stats !== undefined && <div>
          <div className='grid p-fluid'>
            <div className='text-white col-12 md:col-8 grid flex justify-content-center align-items-center align-content-center'>

              <div className='col-6'>
                {returnCustomCard("KWH/Ltr", getValues(stats.dieselStatMTD[0]?.KWHL, stats.dieselStatYTD[0]?.KWHL))},
              </div>

              <div className='col-6'>
                {returnCustomCard("Cost/KWH", getValues(stats.dieselStatMTD[0]?.costKWH, stats.dieselStatYTD[0]?.costKWH))},
              </div>

              <div className='col-6'>
                {returnCustomCard("Cost/AvRoom", getValues(stats.dieselStatMTD[0]?.costPerAvailableRoom, stats.dieselStatYTD[0]?.costPerAvailableRoom))},
              </div>

              <div className='col-6'>
              
                {returnCustomCard("Cost/OccRoom", getValues(stats.dieselStatMTD[0]?.costPerOccupiedRoom, stats.dieselStatYTD[0]?.costPerOccupiedRoom))},
              </div>
            </div>

            <div className='text-white col-12 md:col-4 flex flex-column justify-content-center'>
            <div className='relative text-center'>
                  <img width='100%' height='auto'  className='' src='/assets/images/meter.png' alt='Meter'>
                  </img>
                  <div style={{top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'}} className='absolute  text-center'>
        <span style={{ fontSize: '40px' }}>
          {getValues(stats.dieselStatMTD[0]?.storage, stats.dieselStatYTD[0]?.storage)}
        </span>
        <br></br>
        -{"Available Diesel"}
      </div>

                </div>           
                 </div>


          </div>

        </div>
      }

<DataTable  rows={10} paginator responsiveLayout="scroll" value={list}>

<Column body={(data, props) => <div>{props.rowIndex + 1}</div>} filter header="Sr" />
<Column filter field="storage" header="Storage leters" />
<Column filter field="unitPrice" header="Per unit price" />
<Column filter field="createdAt" body={(data)=><div>{formatDate(data.createdAt)}</div>} header="Date" />

<Column header="Action" body={actionTemplate} />

</DataTable>


      
    </>
  )
}

import React from 'react'
import { useState } from 'react';
import GraphType from '../../../components/graph_type';
import { useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Chart } from 'primereact/chart';
import { appCommonService } from '../../../../services/app_common_service';
import { getDayMonth } from '../../../../services/date_service';
import { Carousel } from 'primereact/carousel';
import { ProgressBar } from 'primereact/progressbar'
import ComparisonSourceComponent from '../components/comparison_source';
import { useDispatch, useSelector } from 'react-redux';
import { getUserBranches } from '../../../../redux/branches/user_branch_slices';
import { getBranchComparison, setSelectedComparisonBranch } from '../../../../redux/comparison_slice/comparison_slice';
import CustomLoading from '../../../components/custom_spinner';


export default function ComparisonScreen() {
    const [selectedGraphType, setSelectedCostGraphType] = useState("ytd");
const { data: branches, loading } = useSelector((state) => state.userBranches);
const { data,loading:comparisonLoading,selectedBranch,selectedSource } = useSelector((state) => state.comparison);

const dispatch = useDispatch();

var colors=[
    '#FF5733',
    '#5000A3',
    '#FFDA33',
    '#5AF50D',
    '#0D65F5',
    '#EE0DF5',
    '#FF5733',
    '#5000A3',
    '#FFDA33',
    '#5AF50D',
    '#0D65F5',
    '#EE0DF5',
]


useEffect(() => {
    dispatch(getUserBranches());
  }, [])


    const [roomsValues,setRoomsValues]=useState([]);



    const [basicData, setBasicData] = useState({});

    useEffect(()=>{

        

        if(data!=undefined){
            if(!isMTDSelected()){
                handleYTD(data);
                handleYTDRoomsValues(data);
            }else{
                handleMTD(data);
                handleMTDRoomsValues(data);
            }
        }
        

    },[selectedGraphType,data]);


    const handleMTDRoomsValues=(data)=>{
        let values=[];
        let perRoomCostMTD=data.perRoomCostMTD;
        let totalRoomsAverage=perRoomCostMTD.map((item,index)=>{
            return {branch:item.branch,
            value:item.totalRoomsAvg,
            color:colors[index]
        }
        });
        values.push({
            title:"Total Rooms Average",
            values:totalRoomsAverage,
           
        });

        setRoomsValues(values)

    }
    const handleYTDRoomsValues=(data)=>{
        let values=[];
        let perRoomCostYTD=data.perRoomCostYTD;
        let totalRoomsAverage=perRoomCostYTD.map((item,index)=>{
            return {branch:item.branch,
            value:item.totalRoomsAvg,
                color:colors[index]
        }
        });
        if(selectedSource!='hlp'){
            let totalRoomsUnitAverage=perRoomCostYTD.map((item,index)=>{
                return {branch:item.branch,
                value:item.totalRoomsUnitAvg,
                    color:colors[index]
            }
            });
            values.push({
                title:"Total Rooms Unit Average",
                values:totalRoomsUnitAverage
            });
        }
    
        let occupiedRoomsAverage=perRoomCostYTD.map((item,index)=>{
            return {branch:item.branch,
            value:item.occupiedRoomsAvg,
                color:colors[index]
        }
        });
       if(selectedSource!='hlp'){
        let occupiedRoomsUnitAverage=perRoomCostYTD.map((item,index)=>{
            return {branch:item.branch,
            value:item.occupiedRoomsUnitAvg,
                color:colors[index]
        }
        });
           
        values.push({
            title:"Occupied Rooms Unit Average",
            values:occupiedRoomsUnitAverage
        });
       }
       
        values.push({
            title:"Total Rooms Cost Average",
            values:totalRoomsAverage
        });
        values.push({
            title:"Occupied Rooms Cost Average",
            values:occupiedRoomsAverage
        });
     

        setRoomsValues(values)
    }


    const handleYTD=(data)=>{
        let datasets=[];

        let months=[];
        let ytd=data.YTD;
        var sets=null;
        for(var month of ytd){
            months.push(appCommonService.getMonthName(month._id));
            if(sets != null){
                for(var i=0;i<month.branches.length;i++){
                    
                    let prev=sets.hasOwnProperty(month.branches[i].branch)? sets[month.branches[i].branch]:[];
                    sets[month.branches[i].branch]=[...prev,month.branches[i].CY];
                }
            }else{
                sets={}
                for(var i=0;i<month.branches.length;i++){
                    sets[month.branches[i].branch]=[month.branches[i].CY];
                }
            }
        }
        
        let keys={};
        if(sets!=null)
        for(var i=0;i<Object.keys(sets).length;i++){
            keys[Object.keys(sets)[i]]=selectedBranch==Object.keys(sets)[i]?"Own":"Company "+(i+1).toString();
        }
        var count=0;
        if(sets!=null)
        Object.keys(sets).forEach(function(key) {
            

            datasets.push({
                label: keys[key],
                backgroundColor:colors[count],
                borderRadius: 10,
                data: sets[key],
                // barThickness: 10,
            });
            count++;
          })
          setBasicData({
            labels: months,
            datasets: datasets
        })
    }

    const handleMTD=(data)=>{
        let datasets=[];
        let days=[];
        let mtd=data.MTD;
        var sets=null;
        for(var day of mtd){
            days.push(getDayMonth(day._id));
            if(sets != null){
                for(var i=0;i<day.branches.length;i++){
                    let prev=sets.hasOwnProperty(day.branches[i].branch)? sets[day.branches[i].branch]:[];

                    sets[day.branches[i].branch]=[...prev,day.branches[i].CY];
                }
            }else{
                sets={}
                for(var i=0;i<day.branches.length;i++){
                    sets[day.branches[i].branch]=[day.branches[i].CY];
                }
            }
        }
        
        let keys={};
        if(sets!=null)
        for(var i=0;i<Object.keys(sets).length;i++){
            keys[Object.keys(sets)[i]]=(i+1).toString();
        }
         
       
        var count=0;

        if(sets!=null)
        Object.keys(sets).forEach(function(key) {
            datasets.push({
                label: keys[key],
                backgroundColor:colors[count],
                borderRadius: 10,
                data: sets[key],
                // barThickness: 10,
            });
          })

          setBasicData({
            labels: days,
            datasets: datasets
        })

    }



   

    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: 1.1,
        categoryPercentage: .4, // here 
        barPercentage: .8,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: 'transparent'
                },

            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: 'transparent'
                }
            }
        }
    };



    const isMTDSelected=()=>{
        return selectedGraphType=='mtd';
    }

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '600px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    

    const productTemplate = (value) => {
        return (
           <>
            <div>
                <h4>{value.title}</h4>
            </div>
            <div>
           {
            value.values.map((item)=>{
                return  <>
                <h6> {item.value.toFixed(2)}</h6>
                <ProgressBar color={item.color} showValue={false} value={((item.value / (item.value*1.10)) * 100)}></ProgressBar>
              </>
            })
           }
            </div>
           </>
        );
    }
  return (
    <div>
        
        <div>
        <Dropdown className='customDropDown my-4' style={{
          background: 'linear-gradient(to bottom, #7c5382, #842990)',
          borderRadius: '4px',
          color: "white !important"
        }} placeholder='Select' optionLabel='name' optionValue='_id' value={selectedBranch} onChange={(e) =>{
            dispatch(setSelectedComparisonBranch(e.target.value))
            dispatch(getBranchComparison(e.target.value));
        }} options={branches} />
      </div>
    {comparisonLoading?
    
    <CustomLoading/>
    :
     
       <div>
       {selectedBranch&&<ComparisonSourceComponent/>}
        <div className='card'>
            <div className='flex justify-content-between'>
            <h4>
                HLP Cost
            </h4>

            <div>
            {/* <GraphType selectedGraphType={selectedGraphType} setSelectedGraphType={setSelectedCostGraphType}/> */}
            </div>

            </div>

            <Chart type="bar" data={basicData} options={basicOptions} />



        </div>

        <div className='card'>
   
                <Carousel value={roomsValues} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions}
                    itemTemplate={productTemplate} />
         

        </div>
       </div>}



    </div>
  )
}

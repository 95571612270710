import { Image } from 'primereact/image';

import { Button } from 'primereact/button'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import appUrl from '../../../../constants/appUrl';
import { getUserBranches } from '../../../../redux/branches/user_branch_slices';
import { getElectricityBills, getElectricityStats } from '../../../../redux/electricity/electricity_slice';
import CustomLoading from '../../../components/custom_spinner';
import AddEditElectricityBill from '../components/add_edit_electricity_bill';


import ElectricityImages from '../components/electricity_images'
import ElectricityTopGraphs from '../components/electricity_top_graphs';

export default function ElectricityManagementScreen() {


    const { data: branches, loading } = useSelector((state) => state.userBranches);
    const [addDialog, setAddDialog] = useState(false);
    const [editData, setEditData] = useState(null)
    const [selectedBranch,setSelectedBranch]=useState(undefined);

    const {data:bills,loading:billsLoading}=useSelector((state)=>state.electricitySlice);

    


    const dispatch = useDispatch();


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());


    const dialogFuncMap = {
        'addDialog': setAddDialog,

    }


    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }




    const returnTopButtons = () => {
        return <div className='mb-4 flex flex-row justify-content-between align-items-center align-content-center '>

            <div>
                <Dropdown className='customDropDown'  style={{
        background: 'linear-gradient(to bottom, #7c5382, #842990)',
        borderRadius: '4px',
        color:"white !important"
      }} placeholder='Select' optionLabel='name' optionValue='_id' value={selectedBranch} onChange={(e)=>setSelectedBranch(e.target.value)} options={branches} />
            </div>


            <div className='flex flex-row align-content-center align-items-center gap-3'>
          
                <div className='datepicker-wrapper mr-2' >
                 
                    <div className='flex flex-row justify-content-start'>
                    <DatePicker  showYearDropdown={true} showYearPicker={true} className='customDatePicker' selected={startDate} onChange={(date) => setStartDate(date)} />
                    <i className="pi pi-calendar  text-white mr-1"></i>
                    </div>
          
                </div>
           
                <Button className='customButton' label='Add New' onClick={() => {
                    setEditData(null);
                    onClick('addDialog');

                }} />

            </div>

        </div>

    }


    useEffect(() => {
        dispatch(getUserBranches());
    }, [])

    useEffect(()=>{
        if(selectedBranch!==undefined){
            dispatch(getElectricityStats(selectedBranch))
            dispatch(getElectricityBills({branch:selectedBranch,year:startDate}));
        }
    },[selectedBranch,startDate,dispatch])

    return (
        <>
      { <div>
            <div className='text-center'>
                <Dialog header="Branch" modal={true} visible={addDialog} style={{ width: '50vw' }} onHide={() => onHide('addDialog')}>
                    <AddEditElectricityBill editData={editData} onHide={() => onHide('addDialog')} />
                </Dialog>

                <h3 className='text-white'>Electricity Bill Management</h3>
            </div>
            <hr></hr>

            <ElectricityTopGraphs/>
            {/* <ElectricityImages /> */}
            {returnTopButtons()}

            {
             bills!==undefined&& <DataTable loading={loading} rows={10} paginator responsiveLayout="scroll" value={bills}>

                  <Column body={(data, props) => <div>{props.rowIndex + 1}</div>} filter header="Sr" />
                  <Column filter field="month" header="month" />
                  <Column filter field="year" header="Year" />
                  <Column filter field="cost" header="Cost" />
                  <Column filter field="units" header="Units" />
                  <Column filter field="rate" header="rate" />
                  <Column filter field="PF" header="PF" />
                  <Column filter field="MD" header="MD" />
                  <Column filter field="AD" header="AD" />
                  <Column filter body={(data)=>{
                      if(data.image!==null){
                          return <Image height='50px' width='60px' src={`${appUrl.fileBaseUrl}${data.image}`} />
                      }else{
                          return <div></div>
                      }
                  }} header="Image" />
                  
                  
                  
                  </DataTable>

            }

            {
                bills!==undefined&&bills.length===0&&<div className='text-center'><h5>No bills found</h5></div>
            }







        </div>}
        </>
    )
}

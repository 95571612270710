import Axios from 'axios';
import appUrl from '../../constants/appUrl';
import { axiosApi } from '../../services/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const addBranchSlice = createSlice({
    name: 'addBranch',
    initialState: {},
    reducers:{
        resetAddBranch(state, action) {

            return {};
        }
           

    },
    extraReducers: (builder) => {
        builder
            .addCase(addBranch.pending, (state, action) => {
                return { loading: true }
            })
            .addCase(addBranch.fulfilled, (state, action) => {

                return { loading: false, data: action.payload ,success:true}
            })
            .addCase(addBranch.rejected, (state, action) => {
                return {
                    loading: false,
                    success:false,
                    error: action.payload
                }
            });
    },
});

export const { resetAddBranch } = addBranchSlice.actions;


export default addBranchSlice.reducer;

// Thunks
export const addBranch = createAsyncThunk('addBranch/fetch', async ( body,{ rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state=getState();
        let headers={
            headers: {
              Authorization: 'Bearer ' + state.loginUser.user.token
            }
          };
          console.log(headers);
          let url=appUrl.baseUrl + appUrl.addBranch;
          console.log(url);
        const { data } = await axiosApi.post(url,body,headers);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});






import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import * as Yup from 'yup';
import CustomInputField from './../../../components/custom_input_field'
import { Button } from 'primereact/button';
import { addUser, resetAddUser } from '../../../../redux/user_management/add_user_slice';
import { editUser, resetEditUser } from '../../../../redux/user_management/edit_user_slice';
import { getAllUsers } from '../../../../redux/user_management/get_all_user_slice';
export default function AddNewUserDialog({ onHide,editUser: editData }) {

  //hooks
  const { data: roles, loading: roleLoading } = useSelector((state) => state.getRoles);
  const { success, loading, error } = useSelector((state) => state.addUser);
  const { success:editSuccess, loading:editLoading, error:editError } = useSelector((state) => state.editUser);
  const { user } = useSelector((state) => state.loginUser);


  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    password:editData!==null?Yup.string().min(8,'Minimum length should be 8'): Yup.string().required("Password is required.").min(8, 'Minimum length should be 8'),
    email: Yup.string().required("Email is required."),
    firstName: Yup.string().required("Please enter first name"),
    lastName: Yup.string().required("Please enter last name"),
    role: Yup.string().required("Please select a role"),
  });

  const formik = useFormik({
    initialValues: {
      "firstName": "",
      "lastName": "",
      "email": "",
      "password": "",
      "role": "",

    },
    validationSchema: validationSchema,
    onSubmit: async (data) => {

      data['company'] = user.company._id;
   


 
      if(editData===null){
        dispatch(addUser(data));
      }else{
        data['userId']=editData._id;
        dispatch(editUser(data));

      }
   


    },
  });





  useEffect(() => {
    if (success !== undefined) {
      if (success === false) {
        toast.error(error)

      } else {
        onHide();
        dispatch(getAllUsers());
      }

    }
    if (editSuccess !== undefined) {
      if (editSuccess === false) {
        toast.error(editError)

      } else {
        onHide();
        dispatch(getAllUsers());
      }

    }
  
  }, [success,editSuccess]);

  const loadDefaultValues=()=>{
    console.log(editData);
    formik.setFieldValue('firstName',editData.firstName);
    formik.setFieldValue('lastName',editData.lastName);
    formik.setFieldValue('email',editData.email);
    formik.setFieldValue('role',editData.role._id);

  }


  useEffect(() => {

    if(editData!=null){
      loadDefaultValues();
    }

    return () => {
      dispatch(resetAddUser());
      dispatch(resetEditUser());
    }
  }, [])



  return (
    <div>
      <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
        <div className="col-12 md:col-6\8">
          <div className=" p-fluid">
            <CustomInputField iden='firstName' formik={formik} placeHolder='Enter first name' type='text' />
            <CustomInputField iden='lastName' formik={formik} placeHolder='Enter last name' type='text' />
            <CustomInputField iden='email' formik={formik} placeHolder='Enter name' type='email' />
            <CustomInputField iden='password' formik={formik} placeHolder='Enter last name' type='password' />
            <CustomInputField iden='role' formik={formik} placeHolder='Select role' type='dropdown' options={roles} optionLabel='role' optionValue='_id' />

            <Button loading={loading} label='Submit' className='customButton' type='submit' />


          </div>
        </div>
      </form>

    </div>
  )
}

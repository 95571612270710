import Axios from 'axios';
import appUrl from '../../constants/appUrl';
import { axiosApi } from '../../services/axios_api';
import { formatDate } from '../../services/date_service';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const hlpDataSlice = createSlice({
    name: 'hlpDataSlice',
    initialState: {},
    reducers: {
        resetAddHlpData(state, action) {
            return { ...state, addSuccess: undefined ,data:undefined}
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getHlpInitData.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getHlpInitData.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload.data,occupiedRooms:action.payload.occupiedRooms ,dataId:action.payload.dataId,dieselStorage:action.payload.dieselStorage}
            })
            .addCase(getHlpInitData.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });
 

        builder
            .addCase(addHlpData.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addHlpData.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload ,addSuccess:true}
            })
            .addCase(addHlpData.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess:false
                }
            });
        builder
            .addCase(editAds.pending, (state, action) => {
                return { ...state, editLoading: true }
            })
            .addCase(editAds.fulfilled, (state, action) => {

                return { ...state, editLoading: false, editData: action.payload,editSuccess:true }
            })
            .addCase(editAds.rejected, (state, action) => {

                return {
                    ...state, editLoading: false,
                    error: action.payload,
                    editSuccess:false
                }
            });
        builder
            .addCase(getHlpDataByRange.pending, (state, action) => {
                return { ...state, rangeDataLoading: true }
            })
            .addCase(getHlpDataByRange.fulfilled, (state, action) => {

                return { ...state, rangeDataLoading: false, rangeData: action.payload }
            })
            .addCase(getHlpDataByRange.rejected, (state, action) => {

                return {
                    ...state, rangeDataLoading: false,
                    error: action.payload,
                 
                }
            });
    },
});

export default hlpDataSlice.reducer;
export const { resetAddHlpData } = hlpDataSlice.actions;



// Thunks
export const getHlpInitData = createAsyncThunk('hlpData/fetch', async (body, { rejectWithValue, fulfillWithValue,getState }) => {
    console.log(body);
    try {
        let state = getState();
        console.log(state);

        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
        let url=appUrl.baseUrl + appUrl.getHLPInitData+`?branchId=${body.id}&date=${body.date}`;
        console.log(url);
        const { data } = await axiosApi.get(url,headers);
        console.log(data);
        return fulfillWithValue(data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const addHlpData = createAsyncThunk('otherBranches/fetch', async (body, { rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state = getState();


        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addHlpData,body,headers);
        
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const getHlpDataByRange = createAsyncThunk('ads/add', async (body, { rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state = getState();
        console.log(state);

        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };

        let url=appUrl.baseUrl + appUrl.getHlpDateByRange+`?branch=${body.branch}&startDate=${formatDate(body.startDate)}&endDate=${formatDate(body.endDate)}`;
        
        console.log(url);
        const { data } = await axiosApi.get(url,headers);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const editAds = createAsyncThunk('ads/edit', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.put(appUrl.baseUrl + appUrl.baseUrl, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)
    }

});
export const removeUserFromBranch = createAsyncThunk('ads/delete', async (body, { rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state = getState();


        let headers = {
            headers: {
                Authorization: 'Bearer ' + state.loginUser.user.token
            }
        };
    
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.removeBranchUser,body,headers);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});

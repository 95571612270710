import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import AddHLPEntryDialog from '../components/add_hlp_entry_dialog';
import { Button } from 'primereact/button';
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import { getHlpDataByRange } from '../../../../redux/hlp_data/htlp_data_slice';
import CustomLoading from '../../../components/custom_spinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatDate, formateDateTime } from '../../../../services/date_service';
import { JsonToExcel } from "react-json-to-excel";



export default function BranchScreen() {

  const [dynamicColumns,setDynamicColumns]=useState([]);


  
  const params = useParams();
  const { id , name } = params;
  const [addDialog, setAddDialog] = useState(false);
  const {rangeData,rangeLoading}=useSelector((state)=>state.hlpData);
  const {user}=useSelector((state)=>state.loginUser);
  const dispatch=useDispatch();
  const [data,setData]=useState([]);



  const dialogFuncMap = {
    'addDialog': setAddDialog,

  }

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);


  }

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  }
  
  const [startDate, setStartDate] = useState(new Date()-( (24*60*60*1000) * 1));
  const [endDate, setEndDate] = useState(new Date());


  useEffect(() => {
    let data={
      branch:id,
      startDate:startDate,
      endDate:endDate
    }
    dispatch(getHlpDataByRange(data))
   
  }, [startDate,endDate])

  useEffect(() => {
    if(rangeData!==undefined&&rangeData.length>0&&rangeData[0].data.length>0){
      console.log(rangeData);

      let tempData=[]


      var list=[];
      list.push({
        field:'date',
        header:'Date'
      })

      let isFirstTime=true;
     for(var item of rangeData){
       var tempValue={};
      for(var i=0;i<rangeData[0].data.length;i++){
        let name1= `${rangeData[0].data[i].source.type}budgetedUnits`;
        let name2= `${rangeData[0].data[i].source.type}units`;
        let name3= `${rangeData[0].data[i].source.type}budgetedUnitCost`;
        let temp2={};
        temp2[name1]=item.data[i].budgetedUnits;
        temp2[name2]=item.data[i].units;
        temp2[name3]=item.data[i].budgetedUnitCost;

        tempValue={
          ...tempValue,
          ...temp2,
          date:formatDate(item.date)
          
        }
    
      if(isFirstTime){
        list.push({
          field:`${rangeData[0].data[i].source.type}budgetedUnits`,
          header:`${rangeData[0].data[i].source.type} Budgted Units`,
        });
        list.push({
          field:`${rangeData[0].data[i].source.type}units`,
          header:`${rangeData[0].data[i].source.type}/${rangeData[0].data[i].source.unit}`,
          // header:`${rangeData[0].data[i].source.type} ${rangeData[0].data[i].source.unit}`,
        });
        list.push({
          field:`${rangeData[0].data[i].source.type}budgetedUnitCost`,
          header:`${rangeData[0].data[i].source.type} Cost/Unit`,
        });
      }
       
       

      }
      tempData.push(tempValue)
      isFirstTime=false;
     } 

     setData(tempData);
   
      

  

    setDynamicColumns(list)
    }

  
  }, [rangeData])
  
  

  const getDynamicColumns = dynamicColumns.map((col,i) => {
    return <Column key={col.field} field={col.field} header={col.header} />;
});



// const exportPdf = () => {
//   import('jspdf').then((jsPDF) => {
//       import('jspdf-autotable').then(() => {
//           const doc = new jsPDF.default(0, 0);

//           doc.autoTable(exportColumns, products);
//           doc.save('products.pdf');
//       });
//   });
// };

const exportExcel = () => {
  // import('xlsx').then((xlsx) => {
  //     const worksheet = xlsx.utils.json_to_sheet(products);
  //     const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
  //     const excelBuffer = xlsx.write(workbook, {
  //         bookType: 'xlsx',
  //         type: 'array'
  //     });

  //     saveAsExcelFile(excelBuffer, 'products');
  // });
};
const exportCSV = () => {
  console.log(dt.current);
  return;
  dt.current.exportCSV();
};

const saveAsExcelFile = (buffer, fileName) => {
  // import('file-saver').then((module) => {
  //     if (module && module.default) {
  //         let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  //         let EXCEL_EXTENSION = '.xlsx';
  //         const data = new Blob([buffer], {
  //             type: EXCEL_TYPE
  //         });

  //         module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  //     }
  // });
};
var dt = useRef(null);

const header = (
  <div className="flex align-items-center export-buttons">
      <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" />
      <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mr-2" data-pr-tooltip="XLS" />
      {/* <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning mr-2" data-pr-tooltip="PDF" /> */}
  </div>
);

  return (
    <>
      <div>

        <Dialog header="Add/Edit" modal={true} visible={addDialog} style={{ width: '50vw' }} onHide={() => onHide('addDialog')}>
          <AddHLPEntryDialog id={id} onHide={() => onHide('addDialog')} />
        </Dialog>


        <div className='flex flex-row justify-content-between'>
          <h2 className='text-white'>{name}</h2>
          <div className='my-3 flex flex-row justify-content-end'>

<div className='datepicker-wrapper flex flex-row'>
  <DatePicker className='customDatePicker' selected={startDate} onChange={(date) => setStartDate(date)} />
  <i className="pi pi-calendar  text-white mr-1"></i>

  <DatePicker className='customDatePicker' selected={endDate} onChange={(date) => setEndDate(date)} />
  <i className="pi pi-calendar text-white"></i>
</div>

</div>
        
        </div>

       <div className='flex flex-row justify-content-end mb-2'>
       {user.role.sku==='CHE'&&<Button className='customButton' label='Add/Edit' onClick={() => {
            onClick('addDialog');
          }} />}
       </div>

       

{
  rangeLoading===true||rangeData===undefined?<CustomLoading/>:
  <div >

  { data.length>0&& <div className='flex justify-content-end m-5'>
    <JsonToExcel
        title="Export"
        data={data}
        fileName="sample-file"
        btnClassName="custom-classname"
      />
    {/* <Button icon="pi pi-file-excel" className="p-button-success p-button-rounded" aria-label="Export to Excel" /> */}

    

  <div>

  </div>
      </div>}

   <DataTable 

  
        value={data} 
        responsiveLayout="scroll" 
        rows={10} 
        paginator >
                    {getDynamicColumns}
                </DataTable>


    </div>
}




      </div>
    </>
  )
}

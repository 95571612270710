import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { getHlpDetails } from '../../../../redux/dashboardSlices/hlp_details_slice';
import { formatDate } from '../../../../services/date_service';
import CustomLoading from '../../../components/custom_spinner';

export default function HlpDetailsScreen() {
    const { branchId, sourceId } = useParams();

    const { data, loading, error} = useSelector((state) => state.hlpDetails);
    const dispatch = useDispatch();

    const [selectedGraphType,setSelectedGraphType]=useState('ytd');

    useEffect(() => {
        dispatch(getHlpDetails({ branchId, sourceId }))


    }, [])

    return (

        <>
            {
                loading === true || data === undefined ? <CustomLoading /> :
                    <div>
                          <div>
            <h3 className='text-white text-center'>HLP Data</h3>

          </div>
          <hr></hr>

                        

                        <div>
                        <div className=' flex justify-content-end mb-2'>
                <div className='datepicker-wrapper flex flex-row align-content-center align-items-center justify-content-center'>
                  <div>
                    <h6 style={{ margin: '0' }} onClick={() => {
                      dispatch(setSelectedGraphType('ytd'));
                    }} className='text-white mr-2'>YTD</h6>
                  </div>
                  {/* <DatePicker className='customDatePicker' selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                  <div>
                    <i className="pi pi-calendar  text-white mr-1"></i>
                  </div>
                  <div>
                    <h6 style={{ margin: '0' }} onClick={() => {
                      dispatch(setSelectedGraphType('mtd'));
                    }} className='text-white ml-2'>MTD</h6>
                  </div>
                  {/* <DatePicker className='customDatePicker' selected={endDate} onChange={(date) => setEndDate(date)} /> */}
                  <div>     <i className="pi pi-calendar text-white"></i></div>
                </div>

              </div>

                            {
                                selectedGraphType === 'mtd' ?
                                    <div>
                                        <DataTable loading={loading} rows={10} paginator responsiveLayout="scroll" value={data.page2MTD}>

                                            <Column body={(data, props) => <div>{props.rowIndex + 1}</div>} filter header="Sr" />
                                            <Column filter  field="date" header="Date" />
                                            <Column filter field="units" header="Units" />
                                            <Column filter field="lastYearUnits" header="Units LY" />
                                            <Column filter field="budgetedUnits" header="Budget Units" />
                                            <Column filter field="cost" header="Cost" />
                                            <Column filter field="lastYearCost" header="Cost LY" />
                                            <Column filter field="budgetedCost" header="Budgetd Cost" />
                                          



                                        </DataTable>
                                    </div>


                                    :
                                    <div>
                                        <DataTable loading={loading} rows={10} paginator responsiveLayout="scroll" value={data.page2YTD}>

                                            <Column body={(data, props) => <div>{props.rowIndex + 1}</div>} filter header="Sr" />
                                            <Column filter field="_id" header="Month" />
                                            <Column filter field="units" header="Units" />
                                            <Column filter field="lastYearUnits" header="Units LY" />
                                            <Column filter field="budgetedUnits" header="Budget Units" />
                                            <Column filter field="cost" header="Cost" />
                                            <Column filter field="lastYearCost" header="Cost LY" />
                                            <Column filter field="budgetedCost" header="Budgetd Cost" />



                                        </DataTable>
                                    </div>
                            }
                        </div>




                    </div>
            }
        </>
    )
}

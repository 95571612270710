import appUrl from '../../constants/appUrl';
import { axiosApi } from '../../services/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');
function addZeroPrefix(num) {
    if (num.toString().length === 1) {
      return "0" + num.toString();
    } else {
      return num.toString();
    }
  }
  

const dashboardDataSlice = createSlice({
    name: 'getDashboardDataSlice',
    initialState: {   
         
     },
    reducers:{
        setSelectedBranch(state,action){
            return {...state,selectedBranch:action.payload}
        },
        setSelectedSource(state,action){
            return {...state,selectedSource:action.payload}
        },
        setGraphType(state,action){
            return {...state, graphType:action.payload}
        },
        setSelectedMonth(state,action){
            return {...state, selectedMonth:action.payload}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardData.pending, (state, action) => {
                return {loading:true}
            })
            .addCase(getDashboardData.fulfilled, (state, action) => {
               
               
                let data=action.payload;
                let selectedBranch;
                let selectedSource;

            

                console.log(data.branchesList.find((item)=>item._id===selectedBranch));
               
                
               
                return {loading:false,data:data,selectedBranch,selectedSource:'hlp',graphType:'ytd',selectedMonth : `${new Date().getFullYear()}-${addZeroPrefix(new Date().getMonth())}`}
            })
            .addCase(getDashboardData.rejected, (state, action) => {
                return {
                    loading:false,
                    error:action.error.response && action.error.response.data.msg
                    ? action.error.response.data.msg
                    : action.error.message
                }
            });


        builder
            .addCase(getBranchDashboardData.pending, (state, action) => {
                return {...state,loading:true}
            })
            .addCase(getBranchDashboardData.fulfilled, (state, action) => {
                let data=action.payload.data;
                  let prev=state.data;
                  let newData={...prev,...data}
                return {...state,loading:false,data:newData,selectedSource:'hlp'}
            })
            .addCase(getBranchDashboardData.rejected, (state, action) => {
                return {
                    ...state,
                    loading:false,
                    error:action.error.response && action.error.response.data.msg
                    ? action.error.response.data.msg
                    : action.error.message
                }
            });

        builder
            .addCase(getBranchSourceDashboardData.pending, (state, action) => {
                return {...state,loading:true}
            })
            .addCase(getBranchSourceDashboardData.fulfilled, (state, action) => {
                let data=action.payload.data;
                  let prev=state.data;
                  
                  let newData={...prev,...data}
                
               
                return {...state,loading:false,data:newData}
            })
            .addCase(getBranchSourceDashboardData.rejected, (state, action) => {
                return {
                    ...state,
                    loading:false,
                    error:action.error.response && action.error.response.data.msg
                    ? action.error.response.data.msg
                    : action.error.message
                }
            });
        builder
            .addCase(getBranchDashboradDataByMonth.pending, (state, action) => {
                return {...state,loading:true}
            })
            .addCase(getBranchDashboradDataByMonth.fulfilled, (state, action) => {
                let data=action.payload.data;
                  let prev=state.data;
                  
                  let newData={...prev,...data}
                
               
                return {...state,loading:false,data:newData}
            })
            .addCase(getBranchDashboradDataByMonth.rejected, (state, action) => {
                return {
                    ...state,
                    loading:false,
                    error:action.error.response && action.error.response.data.msg
                    ? action.error.response.data.msg
                    : action.error.message
                }
            });
    },
});

export const {setSelectedBranch,setSelectedSource,setGraphType,setSelectedMonth}=dashboardDataSlice.actions;

export default dashboardDataSlice.reducer;

// Thunks
export const getDashboardData = createAsyncThunk('dashboardData/fetch', async ( body,{ rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state=getState();
        
        // let year=state.dashboardData.selectedMonth.split("-")[0];
        // let month=parseInt(state.dashboardData.selectedMonth.split("-")[1])-1;
        let headers={
            headers: {
              Authorization: 'Bearer ' + state.loginUser.user.token
            }
          };
          
          let url=appUrl.baseUrl + appUrl.dashboardData;
          console.log(url);
        const { data } = await axiosApi.get(url,headers);
      
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const getBranchDashboardData = createAsyncThunk('dashboardDataBranch/fetch', async ( id,{ rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state=getState();
        let year=state.dashboardData.selectedMonth.split("-")[0];
        let month=parseInt(state.dashboardData.selectedMonth.split("-")[1])-1;
        let headers={
            headers: {
              Authorization: 'Bearer ' + state.loginUser.user.token
            }
          };
          
          let url=appUrl.baseUrl + appUrl.dashboardData+`?branchId=${id}&source=hlp&month=${month}&year=${year}`;
          console.log(url);
        const { data } = await axiosApi.get(url,headers);
      
        return fulfillWithValue(data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const getBranchSourceDashboardData = createAsyncThunk('dashboardSourceDataBranch/fetch', async ( id,{ rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state=getState();
        let year=state.dashboardData.selectedMonth.split("-")[0];
        let month=parseInt(state.dashboardData.selectedMonth.split("-")[1])-1;
        
       
        let headers={
            headers: {
              Authorization: 'Bearer ' + state.loginUser.user.token
            }
          };
          let selectedBranch=state.dashboardData.selectedBranch;
          if(selectedBranch===undefined){
              selectedBranch='all';
          }
          let url=appUrl.baseUrl + appUrl.dashboardData+`?source=${id}&branchId=${selectedBranch}&month=${month}&year=${year}`;
          console.log(url);
        const { data } = await axiosApi.get(url,headers);
      
        return fulfillWithValue(data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});

export const getBranchDashboradDataByMonth = createAsyncThunk('dashboradDataByMonth/fetch', async ( value,{ rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let year=value.split("-")[0];
        let month=parseInt(value.split("-")[1])-1;
        let state=getState();
        let headers={
            headers: {
              Authorization: 'Bearer ' + state.loginUser.user.token
            }
          };

          let selectedBranch=state.dashboardData.selectedBranch;
          let selectedSource=state.dashboardData.selectedSource;
          if(selectedBranch===undefined){
              selectedBranch='all';
          }
          
          let url=appUrl.baseUrl + appUrl.dashboardData+`?branchId=${selectedBranch}&source=${selectedSource}&month=${month}&year=${year}`;
          console.log(url);
        const { data } = await axiosApi.get(url,headers);
      
        return fulfillWithValue(data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
import React from 'react'
import { ProgressBar } from 'primereact/progressbar'

export default function GraphComponents({ values, title }) {
    return (

        <div className='card'>
            <h5>{title}</h5>

            {
                values.map((item) => <div>
                    <h6 className='my-2'>{item.title}  {item.value.toFixed(2)}</h6>
                    <ProgressBar color='#842990' showValue={false} value={item.percent}></ProgressBar>
                </div>)
            }


        </div>

    )
}

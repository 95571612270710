import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppConfig } from './AppConfig';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import LoginScreen from './app/features/screens/auth/pages/login_screen';
import DashboardScreen from './app/features/screens/dashboard/pages/dashboard_screen';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserManagementScreen from './app/features/screens/user_management/pages/user_management_screen';
import BranchesScreen from './app/features/screens/branch_management/pages/branches_screen';
import BranchScreen from './app/features/screens/branch_management/pages/branch_screen';
import BranchUserManagementScreen from './app/features/screens/branch_management/pages/branch_user_management_screen';
import "react-datepicker/dist/react-datepicker.css";
import 'primeicons/primeicons.css';
import ElectricityManagementScreen from './app/features/screens/electricity/pages/electricity_management_screen';
import HlpDetailsScreen from './app/features/screens/dashboard/pages/hlp_details_screen';
import DieselManagementScreen from './app/features/screens/diesel_storage/pages/diesel_management_screen';
import ComparisonScreen from './app/features/screens/comparison/pages/comparison_screen';


const App = () => {
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }
    const [dynamicMenu, setDynamicMenu] = useState([]);


    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', 'main', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });


    const { user } = useSelector((state) => state.loginUser);

    useEffect(() => {
        if (user !== undefined) {

            let role = user.role.sku;

            if (role == 'CA') {

                setDynamicMenu([
                    {

                        items: [
                            {
                                label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/',
                            },


                        ],
                    },
                    {

                        items: [

                            {
                                label: 'User Management', icon: 'pi pi-fw pi-users', to: '/api/users/management',
                            },


                        ],
                    },
                    {

                        items: [

                            {
                                label: 'Branch Management', icon: 'pi pi-fw pi-box', to: '/api/branches',
                            },

                        ],
                    },
                    {

                        items: [

                            {
                                label: 'Electricity Management', icon: 'pi-chart-bar', to: '/api/electricity',
                            },

                        ],
                    },
                    {

                        items: [

                            {
                                label: 'Diesel Management', icon: 'pi-chart-bar', to: '/api/diesel',
                            },

                        ],
                    },
                    {

                        items: [

                            {
                                label: 'Comparison', icon: 'pi pi-book', to: '/api/comparison',
                            },

                        ],
                    },



                ]);
            } else if (role === 'CLE') {

                setDynamicMenu([
                    {

                        items: [
                            {
                                label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/',
                            },


                        ],
                    },
                    {

                        items: [

                            {
                                label: 'User Management', icon: 'pi pi-fw pi-users', to: '/api/users/management',
                            },


                        ],
                    },
                    {

                        items: [

                            {
                                label: 'Branch Management', icon: 'pi pi-fw pi-box', to: '/api/branches',
                            },

                        ],
                    },
                    {

                        items: [

                            {
                                label: 'Electricity Management', icon: 'pi-chart-bar', to: '/api/electricity',
                            },

                        ],
                    },
                    {

                        items: [

                            {
                                label: 'Diesel Management', icon: 'pi-chart-bar', to: '/api/diesel',
                            },

                        ],
                    },
                    {

                        items: [

                            {
                                label: 'Comparison', icon: 'pi pi-book', to: '/api/comparison',
                            },


                        ],
                    },



                ]);
            } else {

                setDynamicMenu([
                    {

                        items: [
                            {
                                label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/',
                            },


                        ],
                    },
                    {

                        items: [

                            {
                                label: 'Electricity Management', icon: 'pi pi-fw pi-chart-bar', to: '/api/electricity',
                            },

                        ],
                    },
                    {

                        items: [

                            {
                                label: 'Diesel Management', icon: 'pi-chart-bar', to: '/api/diesel',
                            },

                        ],
                    },
                    {

                        items: [

                            {
                                label: 'Comparison', icon: 'pi pi-book', to: '/api/comparison',
                            },

                        ],
                    },




                ]);

            }


        }
    }, [user])


    return (
        <>
            <ToastContainer />
            {
                user === undefined ?
                    <div>
                        <Route path='/' component={LoginScreen} />
                    </div>


                    :


                    <div className={wrapperClass} onClick={onWrapperClick}>
                        <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                        <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                            mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                        <div className="layout-sidebar app-side-bar" onClick={onSidebarClick}>
                            <AppMenu onToggleMenuClick={onToggleMenuClick} model={dynamicMenu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                        </div>

                        <div className="layout-main-container">
                            <div className="layout-main">


                                <Route path='/' exact component={DashboardScreen} />
                                <Route path='/api/users/management' exact component={UserManagementScreen} />
                                <Route path='/api/branches' exact component={BranchesScreen} />
                                <Route path='/api/electricity' exact component={ElectricityManagementScreen} />
                                <Route path='/api/branches/:id/:name' exact component={BranchScreen} />
                                <Route path='/api/branches/user/management/:id' exact component={BranchUserManagementScreen} />
                                <Route path='/api/hlp/details/:branchId/:sourceId' exact component={HlpDetailsScreen} />
                                <Route path='/api/diesel' exact component={DieselManagementScreen} />
                                <Route path='/api/comparison' exact component={ComparisonScreen} />

                            </div>

                            {/* <AppFooter layoutColorMode={layoutColorMode} /> */}
                        </div>

                        <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                            layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                        <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                            <div className="layout-mask p-component-overlay"></div>
                        </CSSTransition>

                    </div>
            }
        </>

    );

}

export default App;

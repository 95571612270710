import moment from 'moment';

const formatDate=(value)=>{
    return moment(value).format('YYYY-MM-DD');
}
const formatDateForBackend=(value)=>{
    return moment(value).format('YYYY-MMM-DD');
}

const formateDateTime=(value)=>{
    return moment(value).format('YYY-MMM-DD hh:mm a');
}

const formatTime=(value)=>{
    return moment(value).format('hh:mm a');
}

const getDayMonth=(value)=>{
    return moment(value).format('MMM-DD');

}


export {formatDate,formateDateTime,formatTime,getDayMonth};
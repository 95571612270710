import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUserBranches } from '../../../../redux/branches/user_branch_slices';
import { getSources } from '../../../../redux/user_management/get_source_slice';
import AddEditBranchDialog from '../components/add_edit_branch_dialog';

export default function BranchesScreen() {

    const {user}=useSelector((state)=>state.loginUser);

    const {data:branches,loading}=useSelector((state)=>state.userBranches);

    const [addDialog, setAddDialog] = useState(false);
    const [editData,setEditData]=useState(null)
    const dialogFuncMap = {
        'addDialog': setAddDialog,
      
    }

    const history=useHistory();

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    const dispatch=useDispatch();


    useEffect(() => {
        dispatch(getUserBranches());
        dispatch(getSources());
    }, [dispatch]);

    const actionTemplate = (row) => {
        return <div className='flex flex-row'>
          {  user.role.sku==='CA'&&
            <Button icon="pi pi-user-edit" className="p-button-rounded p-button-primary" onClick={() => {
                setEditData(row);
                onClick('addDialog')
              


            }} aria-label="Details" />}
            <Button icon="pi pi-users" className="ml-2 p-button-rounded p-button-primary" onClick={() => {
                history.push(`/api/branches/user/management/${row._id}`);

            }} aria-label="Details" />
            {/* <Button tooltip='Appointments' icon="pi pi-table" className="ml-2 p-button-rounded p-button-primary" onClick={() => {
                history.push(`/api/centers/appointments/${row.centre._id}`)

            }} aria-label="Details" /> */}
        </div>
    }
    
  return (
   <div>
         <Dialog header="Branch" modal={true} visible={addDialog} style={{ width: '50vw' }} onHide={() => onHide('addDialog')}>
              <AddEditBranchDialog editData={editData} onHide={() => onHide('addDialog')} />
                </Dialog>


                <div className='flex flex-row justify-content-between'>
        <h2 className='text-white'>Branches</h2>
       {user.role.sku==='CA'&& <Button className='customButton' label='Add' onClick={()=>{
            setEditData(null);
            onClick('addDialog');
        }}/>}
    </div>
    <hr></hr>
    <DataTable loading={loading} rows={10} paginator responsiveLayout="scroll" value={branches}>

<Column body={(data, props) => <div>{props.rowIndex + 1}</div>} filter header="Sr" />
<Column filter field="name" header="Name" />
<Column filter field="address" header="Address" />
<Column filter field="area" header="Area (sq meter)" />
<Column filter field="load" header="Load" />
<Column filter field="rooms" header="Rooms" />
<Column filter field="address" header="Address" />
<Column filter body={(data)=>{
    return <div>{data.sources.map((item)=>item.source.type).toString()}</div>
}} header="Sources" />


<Column body={actionTemplate} />

</DataTable>
   </div>
  )
}

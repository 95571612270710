import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import * as Yup from 'yup';
import CustomInputField from './../../../components/custom_input_field'
import { Button } from 'primereact/button';
import { addUser, resetAddUser } from '../../../../redux/user_management/add_user_slice';
import { addBranch, resetAddBranch } from '../../../../redux/branches/add_branch_slice';
import { getUserBranches } from '../../../../redux/branches/user_branch_slices';
export default function AddEditBranchDialog({onHide,editData}) {

  //hooks
  const {success,loading,error}=useSelector((state)=>state.addBranch);
  const {data:sources}=useSelector((state)=>state.getSources);
  const {user}=useSelector((state)=>state.loginUser);


const dispatch=useDispatch();
const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    address:Yup.string().required("Address is required"),
    load:Yup.number().required("Saction load is required"),
    rooms:Yup.number().required("Total rooms are required").min(1,'Minimum rooms must be 1'),
    area:Yup.string().required("Area is required"),
    sources:Yup.array().min(1,'Please choose atleast one source')
  
  });

const formik = useFormik({
  initialValues: {
    "name": "",
    "address": "",
    "load":"",
    "area":"",
    "rooms":"",
    "sources":[]
 
  },
  validationSchema: validationSchema,
  onSubmit: async (data) => {
   
    data['company']=user.company._id;




    dispatch(addBranch(data));
    
  

  },
});





  useEffect(() => {
    if (success !== undefined) {
      if (success === false) {
        toast.error(error)

      }else{
        onHide();
        dispatch(getUserBranches());
      } 
      
    }
  }, [success]);

  useEffect(() => {

    if(editData!==null){
      loadInitValues();
     

    }

    return () => {
      dispatch(resetAddBranch());
    }
    
  
  
  }, []);

  const loadInitValues=()=>{
    formik.setFieldValue('name',editData.name);
    formik.setFieldValue('address',editData.address);
    formik.setFieldValue('load',editData.load);
    formik.setFieldValue('area',editData.area);
    formik.setFieldValue('rooms',editData.rooms);
    let values=editData.sources.map((item)=>item._id);
    console.log(values);

    // formik.setFieldValue('sources',[...values]);
    console.log(formik.values.sources);
  }
  


  return (
   <div>
     
     <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
     
     <div className="col-12 md:col-6\8">
                            <div className=" p-fluid">
     <CustomInputField iden='name' formik={formik} placeHolder='Enter branch name' type='text' />
     <CustomInputField iden='address' formik={formik} placeHolder='Enter address' type='text' />
     <CustomInputField iden='load' label='Contract Demand (KVA)' formik={formik} placeHolder='Enter load' type='number' />
     <CustomInputField iden='area' label='Area (Sq. MTR)' formik={formik} placeHolder='Enter area' type='number' />
     <CustomInputField iden='rooms' formik={formik} placeHolder='Enter total number of rooms' type='number' />
     <CustomInputField iden='sources' options={sources} optionLabel='type' optionValue='_id' formik={formik} placeHolder='Select Sources' type='multiSelect' />
     <Button loading={loading} label='Submit' className='customButton' type='submit' />
     </div>

</div>
     </form>

   </div>
  )
}

import appUrl from '../../constants/appUrl';
import { axiosApi } from '../../services/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');
function addZeroPrefix(num) {
    if (num.toString().length === 1) {
      return "0" + num.toString();
    } else {
      return num.toString();
    }
  }
  

const comparisonDataSlice = createSlice({
    name: 'getcomparisonDataSlice',
    initialState: {   
        selectedSource:"hlp",
         
     },
    reducers:{
        setSelectedComparisonBranch(state,action){
            return {...state,selectedBranch:action.payload}
        },
        setSelectedComparisonSource(state,action){
            return {...state,selectedSource:action.payload}
        },
     
    },
    extraReducers: (builder) => {
    


        builder
            .addCase(getBranchComparison.pending, (state, action) => {
                return {...state,loading:true}
            })
            .addCase(getBranchComparison.fulfilled, (state, action) => {
                let data=action.payload.data;
                  let prev=state.data;
                  let newData={...prev,...data}
                return {...state,loading:false,data:newData}
            })
            .addCase(getBranchComparison.rejected, (state, action) => {
                return {
                    ...state,
                    loading:false,
                    error:action.error.response && action.error.response.data.msg
                    ? action.error.response.data.msg
                    : action.error.message
                }
            });

        builder
            .addCase(getBranchSourceComparison.pending, (state, action) => {
                return {...state,loading:true}
            })
            .addCase(getBranchSourceComparison.fulfilled, (state, action) => {
                let data=action.payload.data;
                  let prev=state.data;
                  
                  let newData={...prev,...data}
                
               
                return {...state,loading:false,data:newData}
            })
            .addCase(getBranchSourceComparison.rejected, (state, action) => {
                return {
                    ...state,
                    loading:false,
                    error:action.error.response && action.error.response.data.msg
                    ? action.error.response.data.msg
                    : action.error.message
                }
            });
           },
});

export const {setSelectedComparisonBranch,setSelectedComparisonSource}=comparisonDataSlice.actions;

export default comparisonDataSlice.reducer;

// Thunks

export const getBranchComparison = createAsyncThunk('comparisonbranch/fetch', async ( id,{ rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state=getState();
        let selectedSource=state.comparison.selectedSource;
        let headers={
            headers: {
              Authorization: 'Bearer ' + state.loginUser.user.token
            }
          };
          
          let url=appUrl.baseUrl + appUrl.comparisonData+`?branchId=${id}&source=${selectedSource}`;
          console.log(url);
        const { data } = await axiosApi.get(url,headers);
      
        return fulfillWithValue(data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const getBranchSourceComparison = createAsyncThunk('comparison/fetch', async ( id,{ rejectWithValue, fulfillWithValue,getState }) => {
    try {
        let state=getState();
        let branch=state.comparison.selectedBranch;
        
       
        let headers={
            headers: {
              Authorization: 'Bearer ' + state.loginUser.user.token
            }
          };
 
          let url=appUrl.baseUrl + appUrl.comparisonData+`?source=${id}&branchId=${branch}`;
          console.log(url);
        const { data } = await axiosApi.get(url,headers);
      
        return fulfillWithValue(data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});


import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBranchDashboardData, setSelectedBranch } from '../../../../redux/dashboardSlices/dashboard_data_slice';

export default function AllBranchesDialog({onHide}) {
  const dispatch=useDispatch();
  const { data: dashboardData,selectedBranch} = useSelector((state) => state.dashboardData);

  return (
   <>
  <div className='flex flex-wrap'>
  {dashboardData.branchesList.slice(0, 3).map((item, index) => <div onClick={() => {
                  dispatch(setSelectedBranch(item._id));
                  dispatch(getBranchDashboardData(item._id));
                  onHide();
                }} key={index} style={{ boxSizing: 'border-box' }} className={`card dashboard-card col-6 md:col-3 m-2 ${selectedBranch !== undefined && selectedBranch === item._id ? 'selected-branch' : ''}`}>
                  <Link className='link' to={`/api/branches/${item._id}`}>        <h3>{item.name}</h3></Link>
                </div>
              

                
                
                )}

  </div>
   </>
  )
}

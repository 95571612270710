
import { configureStore } from '@reduxjs/toolkit'

import loginUserReducer from '../app/redux/auth_slices/login_user_slice';
import getDashboardData from '../app/redux/dashboardSlices/dashboard_data_slice';
import getRolesReducer from '../app/redux/user_management/get_roles_slice';
import addUserReducer from '../app/redux/user_management/add_user_slice';
import editUserReducer from '../app/redux/user_management/edit_user_slice';
import getAllUsersReducer from '../app/redux/user_management/get_all_user_slice';
import userBranchesReducer from '../app/redux/branches/user_branch_slices';
import addBranchReducer from '../app/redux/branches/add_branch_slice';
import hlpDataReducer from '../app/redux/hlp_data/htlp_data_slice';
import getSourcesReducer from '../app/redux/user_management/get_source_slice';
import branchUserManagmentReducer from '../app/redux/user_management/branch_user_management_slice';
import electricity_slice from './redux/electricity/electricity_slice';
import hlp_details_slice, { getHlpDetails } from './redux/dashboardSlices/hlp_details_slice';
import diesel_management_slice from './redux/diesel_management/diesel_management_slice';
import comparison_slice from './redux/comparison_slice/comparison_slice';

const user=localStorage.getItem("user");

const initialState = {
loginUser:{
  user:user===null?undefined: JSON.parse(user)
}
};


const store = configureStore({
  reducer: {
    loginUser:loginUserReducer,
    dashboardData:getDashboardData,
    getRoles:getRolesReducer,
    getSources:getSourcesReducer,
    addUser:addUserReducer,
    editUser:editUserReducer,
    getAllUsers:getAllUsersReducer,
    userBranches:userBranchesReducer,
    addBranch:addBranchReducer,
    branchUsers:branchUserManagmentReducer,
    hlpData:hlpDataReducer,
    electricitySlice:electricity_slice,
    hlpDetails:hlp_details_slice,
    dieselManagement:diesel_management_slice,
    comparison:comparison_slice
  },
  preloadedState:initialState
},
)



export default store;


import React, { useState }  from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import appAssets from './app/constants/appAssets';
import { Sidebar } from 'primereact/sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { logout } from './app/redux/auth_slices/login_user_slice';


export const AppTopbar = (props) => {

    const [visible,setVisible]=useState(false);
    const {user}=useSelector((state)=>state.loginUser);
    const dispatch=useDispatch();


    return (
        <div className="layout-topbar">

<Sidebar visible={visible} position="right" onHide={() => setVisible(false)}>
    <h3 className='font-bold'>Welcome</h3>
    <h5>{user.firstName} {user.lastName}</h5>
    <h6>{user.email}</h6>
    <h6>{user.role.role}</h6>
    <Button label='Logout' onClick={()=>{
        dispatch(logout())

    }} />
</Sidebar>
            <Link to="/" className="layout-topbar-logo">
                <img width='200px' height='200px' style={{objectFit:'contain'}} src={props.layoutColorMode === 'light' ? appAssets.logo : 'assets/layout/images/logo-white.svg'} alt="logo"/>
             
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                   
                    <li>
                        <button className="p-link layout-topbar-button" onClick={()=>setVisible(true)}>
                            <i className="pi pi-user"/>
                            <span>Profile</span>
                        </button>
                    </li>
                </ul>
        </div>
    );
}

import { ProgressBar } from 'primereact/progressbar'
import React from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function CostRoomConmponents({ heading, list }) {
  const { data: dashboardData,selectedBranch,selectedSource} = useSelector((state) => state.dashboardData);


  const history=useHistory();

let totalValue= list.reduce((accumulator, object) => {
  return accumulator + object.value;
}, 0);


  return (


    <div onClick={()=>{
      console.log("hello");
      if(selectedBranch===undefined){
        return;
      }
      history.push(`/api/hlp/details/${selectedBranch}/${selectedSource}`)

  }} className="p-2  col-12 lg:col-3 md:col-6">
      <div className='card'>
        <h5>{heading}</h5>
        <div>
          {
            list.map((item) => (
              <>
                <h6>{item.title}  {item.value.toFixed(2)}</h6>
                <ProgressBar color='#842990' showValue={false} value={((item.value / totalValue) * 100)}></ProgressBar>
              </>
            ))
          }
        </div>
      </div>
    </div>

  )
}
